/* todo: make this a modal */
import React from "react";
import { Button, Heading } from "react-bulma-components";

const NotFoundView = (props: any) => {
    // Display a large 4-digit prompt for the code and a large top hat icon on top
    return (
        <div
            className="side-entry has-text-centered"
            style={{
                display: props.show ? "flex" : "none",
                flexDirection: "column",
                justifyContent: "center",
            }}
        >
            <br />
            <br />
            <i className="fa-solid fa-ghost fa-5x has-text-grey has-text-centered"></i>
            <br />
            <br />
            <Heading className="title is-4 has-text-warning-light has-text-centered">
                404: Boo!
            </Heading>
            <p className="has-text-grey-light">
                This page is not there anymore
                <br />
                <br />
                <br />
                <Button
                    color="success"
                    outlined
                    rounded
                    onClick={() => props.navigate("find")}
                >
                    explore
                </Button>
            </p>
        </div>
    );
};
export default NotFoundView;
