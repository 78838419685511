/*
 * Wrapper for useMap, useMapEvents, providing global map state
 */

import { useEffect, useState } from "react";
import { useMapEvents } from "react-leaflet";
import L from "leaflet";
import { MapPositionState } from "../../utils/MapPositionState";

/* TODO: merge logic of this into sth else */
// TODO: export flyTo etc!! give state-setting functions to other components (global state?)
const MapWrapper = (props: {
    center?: MapPositionState;
    setCenter: any;
    sidebarShown: boolean;
    sidebar: string;
    setSidebarShown: any;
    navigate: any;
    setUserLocation: any;
    setActualCenter: any;
    setNewEventPosition: any;
    mapLastMoved: number;
    setMapLastMoved: any;
    bounds: any;
    setBounds: any;

}) => {
    // const [center, setCenter] = useState<LatLngTuple>(LocalStorage.getHome() || [51.505, -0.09]);
    // !! TODO/BUG: this broke down
    const map = useMapEvents({
        dblclick(e: any) {
            // TODO: do this only on long press
            // props.setNewEventPosition(e.latlng);
            // props.navigate('add');
            // map.flyTo(e.latlng, 16);
        },
        click(e: any) {
            props.setNewEventPosition(null);
            // close sidebar
            if (props.sidebarShown && props.sidebar === "add") {
                props.setSidebarShown(false);
            }
        },
        locationfound(e: any) {
            map.setView(e.latlng, 14);
            props.setCenter({ coords: e.latlng, flyAnimation: false, requestTimestamp: Date.now() });
            props.setUserLocation(e.latlng);

            // L.marker(e.latlng, {
            //     // icon:
            // }).addTo(map);
            // TODO: remove previous circle:
            map.eachLayer((layer: any) => {
                if (layer.options && layer.options.color === "orange") {
                    map.removeLayer(layer);
                }
            });

            /*L.circle(e.latlng, e.accuracy, {
                color: "transparent",
                fillColor: "orange",
                fillOpacity: 0.1,
                weight: 1,
            }).addTo(map);*/
            let popup = L.popup().setContent(
                "This is home. You appear to be close to this point."
            );
            var marker = L.marker(e.latlng, {
                icon: L.divIcon({
                    className: "font-awesome-icon",
                    html:
                        '<i class="far fa-circle-dot" style="' +
                        "color: lightgreen; font-size: 32px;" +
                        '"></i>',
                    iconSize: [32, 32],
                    iconAnchor: [16, 16],
                }),
            });
            marker.addTo(map);
            marker.bindPopup(popup);

            // TODO: move this to a better place
            let firstTime = localStorage.getItem("firstTime") === null;
            if (firstTime) {
                // show popup
                marker.openPopup();
                localStorage.setItem("firstTime", "false");
            }

            console.log("TODO: set center for return!");
        },
        locationerror(e: any) {
            console.log("TODO: handle location error!");
        },
        moveend(e: any) {
            props.setMapLastMoved(Date.now());
            props.setActualCenter(map.getCenter());

            // set bounds:
            props.setBounds(map.getBounds());
        },
    });

    // TODO: pass flying: bool alongside center state change to identify "flying" moves?
    useEffect(() => {
        if (
            props.center !== null && props.center !== undefined &&
            props.center?.coords !== null && props.center?.coords !== undefined
        ) {
            if(props.center?.flyAnimation) 
                map.flyTo(props.center!.coords, 16);
            else 
                map.setView(props.center!.coords, 16);

            //???props.setCenter(null);
        }
    }, [props.center]);

    useEffect(() => {
        map.locate();
    }, [map]);

    return null;
};
export default MapWrapper;
