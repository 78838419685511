/* 
 * Main map view
 *
 * TODO:
 *  - integrate with https://react-leaflet.js.org/docs/example-external-state/!!
 *  - add global context for flying etc
 */
import React, { useEffect } from "react";
import Moment from "moment";
import moment from "moment";
import { MapContainer, TileLayer, Marker, Tooltip } from "react-leaflet";
import { Heading } from "react-bulma-components";
import L from "leaflet";
import { useState } from "react";
import OrdaEvent from "../../model/OrdaEvent";
import ApiBox from "../../db/ApiBox";
import { MapPositionState } from "../../utils/MapPositionState";
import MapWrapper from "../widgets/MapWrapper";
// import PixiOverlay from "react-leaflet-pixi-overlay";
import supabaseToOrdaEvent from "../../model/OrdaEvent";


import "../../index.css";
import EmptyMarker from "../../assets/marker-empty.png";
import MarkerClusterGroup from "react-leaflet-cluster";

const MapView = (props: any) => {
    const viewEvent = (e: any, mapEvent: OrdaEvent, navigate: any) => {
        navigate("view", mapEvent);
        props.setLoadedEvent(mapEvent);
        props.setHighlightedEvent(mapEvent);
        props.setCenter({ 
            coords: mapEvent.where[0].coords,
            flyAnimation: false,
            requestTimestamp: Date.now(),
        } as MapPositionState);
    };


    // const [pixiMarkers, setPixiMarkers] = useState<any[]>([]);
    // useEffect(() => {
    //     setPixiMarkers(events.map((event) => {
    //         const isFuture = moment(event.when[0].start).isAfter(moment());
    //         const isNearFuture = moment(event.when[0].start).isBefore(moment().add(1, "day"));
    //         const isPast = moment(event.when[0].end).isBefore(moment());
    //         const isLongRunning = moment(event.when[0].end).diff(moment(event.when[0].start), "days") > 1;

    //         // returns double between 0 and 1
    //         const random = (str: string) => {
    //             let hash = 0;
    //             for (let i = 0; i < str.length; i++) {
    //                 hash = str.charCodeAt(i) + ((hash << 5) - hash);
    //             }
    //             const h = (hash & 0xff) / 255;
    //             return h;
    //         }

    //         const approxLat = event.where[0].coords[0] + (random(event._id) - 0.5) * 0.001;
    //         const approxLng = event.where[0].coords[1] + (random(event._id + "x") - 0.5) * 0.001;

    //         return ( 
    //             props.shownSources.indexOf(event.what.tags[0]) > -1 || props.shownSources.indexOf(event.meta.spawner) > -1 ) &&
    //             (!isPast || props.showPast) &&
    //             (!isFuture || props.showFuture) ? (
    //                 {
    //                     id: event._id,
    //                     position: [approxLat, approxLng],
    //                     data: event,
    //                     iconColor: isFuture ? (
    //                         isNearFuture ? "hsl(44,  100%, 77%)" : "grey" // Bulma: [warning, grey]
    //                     ) : isPast  ? "hsl(0, 0%, 71%)" // Bulma: [grey-light]
    //                                 : (
    //                                     isLongRunning ? "hsl(153, 43%, 63%)" : "hsl(133, 53%, 53%)" // Bulma: [success, grey-ish success]
    //                                 ),

    //                     tooltip: `TODO: ${event.what.name}`,

    //                     onClick: (e: any) => viewEvent(e, event, props.navigate),
    //                 }
    //             ) : null;
    //     }).filter((event) => event !== null));
    //     console.log("pixiMarkers", pixiMarkers);
    // }, [events]);


    const [loading, setLoading] = useState(true);
    const emptyMarkerIcon = L.icon({
        iconUrl: EmptyMarker,
        iconSize: [28, 28],
        iconAnchor: [14, 14],
    });


    const faMarker = (
        startTime: number,
        endTime: number,
        isPremium = false,
        isCurrent = false
    ) => {

        const isFuture = moment(startTime).isAfter(moment());
        const startsNearFuture = moment(startTime).isBefore(moment().add(1, "day"));
        const isPast = moment(endTime).isBefore(moment());
        // after 50% of the event duration has passed, it's considered almost over if less than 12 hours left
        const endsNearFuture = moment().diff(moment(startTime), "days") > 0.5 * moment(endTime).diff(moment(startTime), "days") && moment(endTime).diff(moment(), "hours") < 12;
        const isLongRunning = moment(endTime).diff(moment(startTime), "days") > 1;
        const isLive = moment(startTime).isBefore(moment()) && moment(endTime).isAfter(moment());


        // red: almost over, 
        // yellow: currently,
        // green: upcoming,
        // grey: future,
        // grey-green: live but long running,
        // purple: custom/promoted
        const green = "hsl(153, 43%, 63%)"; // Bulma success
        const mutedGreen = "hsl(133, 53%, 53%)";
        const orange = "hsl(44,  100%, 77%)"; // Bulma warning
        const mutedOrange = "hsl(44,  80%, 77%)";
        const red = "hsl(4, 70%, 63%)"; // Bulma red

        const iconClass = "";
        const iconColor =
            isFuture ? (
                startsNearFuture ? green : "grey" // Bulma: [warning, grey]
            ) : isPast  ? "hsl(0, 0%, 71%)" // Bulma: [grey-light]
                        : (
                            endsNearFuture ? red : ( // Bulma: [danger]
                                isLongRunning ? orange : orange // Bulma: [success, grey-ish success]
                            )
                        )
        const premiumClass = isPremium ? "fas fa-crown" : "";
        const hiddenClass = ""; // TODO? isCurrent === false ? "almost-hidden" : "";
        const fadeClass = isFuture === false ? "" : "";
        const fontSize = ((isFuture && !startsNearFuture) || isPast || isLongRunning) ? 24 : 28;
        const style = `
            text-shadow: 0 0 4px #000000;
            font-size: ${fontSize}px;
            line-height: ${fontSize}px;
            text-align: center;
            color: ${iconColor};
        `;
        const icon = isLongRunning || (isFuture && !startsNearFuture) ? "far fa-circle" : "fas fa-diamond";
        // TODO: don't use html marker but icon for performance
        const html = `
            <i class="${icon} ${iconClass} ${premiumClass} ${hiddenClass} ${fadeClass}" style="${style}"></i>
        `;
        return L.divIcon({
            html,
            iconSize: [fontSize, fontSize],
            iconAnchor: [fontSize / 2, fontSize],
            className: "font-awesome-icon",
        });
    };

    // Experimental: a state to show which events are currently visible
    // TODO - BIG HACK for visualization only! also move bounds check to server side *as well* of course
    // TODO - this has perhaps dumb high overhead
    // TODO - in other words, burn all this (and the depending states) with fire ASAP
    const [bounds, setBounds] = useState<any>([]);
    useEffect(() => {
        if (props.events && bounds.length !== 0) {
            let eventsFilter = props.events.filter((event: any) => {
                return (
                    bounds._southWest.lat < event.where[0].coords[0] &&
                    event.where[0].coords[0] < bounds._northEast.lat &&
                    bounds._southWest.lng < event.where[0].coords[1] &&
                    event.where[0].coords[1] < bounds._northEast.lng
                );
            }).map(supabaseToOrdaEvent).sort((a: OrdaEvent, b: OrdaEvent) => {
                return a.when[0].start > b.when[0].start ? 1 : -1;
            });

            // TODO! has to be only events in currently enabled time range
            props.__setVisibleEventsInMapArea(eventsFilter);
            
            // HACK: __setEventCountBySource - TODO: remove ASAP
            let eventCountBySource: any = {};
            eventsFilter.forEach((event: OrdaEvent) => {
                if(event.meta && event.meta.spawner)
                    eventCountBySource[event.meta.spawner] = (eventCountBySource[event.meta.spawner] || 0) + 1;
            });
            props.__setEventCountBySource(eventCountBySource);
            
            let eventCountByTopic: any = {};
            eventsFilter.forEach((event: OrdaEvent) => {
                event.what.tags.forEach((tag: string) => {
                    eventCountByTopic[tag] = (eventCountByTopic[tag] || 0) + 1;
                });
            });
            props.__setEventCountByTopic(eventCountByTopic);
        }
    }, [bounds, props.events]);
    // TODO - remove above hack ASAP


    return (
        <MapContainer
            id="map"
            className="map-dark"
            zoom={11}
            minZoom={6} // 8?
            markerZoomAnimation={false} // TODO: fix this (with smooth zoom)
            zoomAnimation={true}
            zoomControl={false}
            center={props.center.coords}
            preferCanvas={true}
        >
            <MapWrapper
                {...props}
                bounds={bounds}
                setBounds={setBounds}
                zoom={13}
                setZoom={() => {}}
            />

            {/* <ZoomControl position="bottomleft" /> */}
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors | orda.zone (<a href="/impressum" target="_blank">impressum</a>)'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {/* TODO use supercluster? */}
            <MarkerClusterGroup 
                disableClusteringAtZoom={13} 
                maxClusterRadius={120}
                chunkedLoading={true}
                zoomToBoundsOnClick={true}
                showCoverageOnHover={false}
                polygonOptions={ { weight: 1, color: 'hsl(0, 0%, 71%) ', opacity: 0.5 } }
                spiderfyOnMaxZoom={false}
                iconCreateFunction={(cluster: any) => {
                    return L.divIcon({ html: '<i class="far fa-circle-dot has-text-grey-light" style="font-size: 24px">&nbsp;' + cluster.getChildCount() + '</i>', 
                    className: "font-awesome-icon"});
                }}
            >
                {props.events.map((event: OrdaEvent) => {
                    // TODO: these flags should be in the event model and backend (prevent getting unaothorized events)
                    const isFuture = moment(event.when[0].start).isAfter(moment());
                    const isNearFuture = moment(event.when[0].start).isBefore(moment().add(1, "day"));
                    const isPast = moment(event.when[0].end).isBefore(moment());

                    // returns double between 0 and 1
                    const random = (str: string) => {
                        let hash = 0;
                        for (let i = 0; i < str.length; i++) {
                            hash = str.charCodeAt(i) + ((hash << 5) - hash);
                        }
                        const h = (hash & 0xff) / 255;
                        return h;
                    }

                    // TODO: ensure ok spacing between randomly placed markers
                    const approxLat = event.where[0].coords[0] + (random(event._id) - 0.5) * 0.001;
                    const approxLng = event.where[0].coords[1] + (random(event._id + "x") - 0.5) * 0.001;
                    // check that ALL shown topics are in the event tags
                    const topicMatch = (!props.shownTopics || props.shownTopics.length === 0 || 
                        props.shownTopics.every((topic: string) => event.what.tags.indexOf(topic) > -1));


                    // TODO: marker clustering??
                    // TODO: fetch & show only markers in and near boundaries (e.g. 2x of width/height)?
                    return (
                        // TODO!!! source filter (props.shownSources.indexOf(event.what.tags[0]) > -1 || props.shownSources.indexOf(event.meta.spawner) > -1 ) &&
                        topicMatch &&
                        (!isPast || props.showPast) &&
                        (!isFuture || isNearFuture || props.showFuture) && (
                            <Marker
                                key={event._id}
                                position={[approxLat, approxLng]}
                                zIndexOffset={
                                    (props.highlightedEvent?._id === event._id) 
                                        ? 100000 : (
                                        (isPast || (isFuture&&!isNearFuture)) ? -1000 : 0)}
                                // icon={L.icon({ iconUrl: `/images/markers/${event.meta.spawner}.png`, iconSize: [28, 28], iconAnchor: [14, 14] })}
                                icon={faMarker(
                                    event.when[0].start,
                                    event.when[0].end,
                                    false,
                                    props.highlightedEvent === undefined ||
                                        props.highlightedEvent._id === event._id
                                )}
                                eventHandlers={{
                                    click: (e: any) =>
                                        viewEvent(e, event, props.navigate),
                                }}
                            >
                                <Tooltip
                                    eventHandlers={{
                                        click: (e: any) =>
                                            viewEvent(e, event, props.navigate),
                                    }}
                                >
                                    <Heading className="title is-6 has-text-primary mb-1">
                                        {event.what.name}
                                    </Heading>
                                    
                                    <span
                                        className="has-text-grey-light"
                                        style={{
                                            width: "100%",
                                            display: "inline-block",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                        }}
                                    >
                                        { !isPast && !isFuture && (
                                            <span className="has-text-warning mr-1">
                                                live now,
                                            </span>
                                        )}
                                        { isFuture && (
                                            isNearFuture ? (
                                                <span className="has-text-success">
                                                    soon,&nbsp;
                                                </span>
                                            ) : (
                                                <span className="has-text-grey-light">
                                                    in {Moment(event.when[0].start).diff(moment(), "days")} days,&nbsp;
                                                </span>
                                            )
                                        )}
                                        { isPast && (
                                            <span className="has-text-grey-light mr-1">
                                                ended {Moment(event.when[0].end).fromNow(true)},
                                            </span>
                                        )}
                                        via
                                        &nbsp;
                                        <i className="fas fa-satellite-dish has-text-white"></i>
                                        &nbsp;
                                        <span className="has-text-white">{event.meta.spawner}</span>
                                        <br />
                                        {event.what.tags.map((name) => (
                                            <span key={name} className="has-text-grey-light">
                                                #{name}&nbsp;
                                            </span>
                                        ))}
                                    </span>
                                </Tooltip>
                            </Marker>
                        )
                    );
                })}
            </MarkerClusterGroup>

            {props.newEventPosition && (
                <Marker
                    key="new-event"
                    icon={emptyMarkerIcon}
                    position={props.newEventPosition}
                    eventHandlers={{ click: (e: any) => props.navigate("add") }}
                >
                    <Tooltip>
                        <Heading className="title is-6 has-text-primary mb-1">
                            New Event
                        </Heading>
                        <b>Tap to add</b>
                    </Tooltip>
                </Marker>
            )}
        </MapContainer>
    );
};

export default MapView;
