import React, { useEffect } from "react";
import WelcomeView from "./WelcomeView";
import ViewView from "./ViewView";
import FindView from "./FindView";
import AddView from "./AddView";
import AboutView from "./AboutView";
import VerifyView from "./VerifyView";
import NotFoundView from "./NotFoundView";
import PrefView from "./PrefView";
import UnlockView from "./UnlockView";
import SourceView from "./SourceView";
import SourcesView from "./PrefSourcesView";
import { LatLngTuple } from "leaflet";
import WalletView from "./WalletView";
import { MapPositionState } from "../../utils/MapPositionState";

const Sidebar = (props: { 
        sidebarShown: boolean,
        sidebar: string,
        setSidebar: (s: string) => void,
        setSidebarShown: (s: boolean) => void,
        navigate: (s: string) => void,
        goHome: () => void,
        setCenter: (c: MapPositionState) => void,
        loadedEvent: any,
        setLoadedEvent: (e: any) => void,
        highlightedEvent: any,
        setHighlightedEvent: (e: any) => void,
        userLocation: LatLngTuple | null,
        setUserLocation: (l: [number, number]) => void,
        setHome: (h: [number, number]) => void,
        newEventPosition: LatLngTuple | null,
        setNewEventPosition: (p: LatLngTuple) => void,
        centerMapAroundNewEvent: () => void,
        loadedSource: any,
        setLoadedSource: (s: any) => void,
        tiledMode: boolean,
        shownSources: string[],
        setShownSources: (s: string[]) => void,
        mapCenter: LatLngTuple,
    }) => {
    
    // jump to top of sidebar on navigation
    useEffect(() => {
        document.getElementById("sidebar")?.scrollTo(0, 0);
    }, [props.sidebar]);

    return (
        <div
            id="sidebar"
            className={(props.tiledMode ? "tiled" : "") + " " + (!props.sidebarShown ? "sidebar-hidden" : "")}
            style={
                props.sidebarShown || props.tiledMode
                    ? {
                            opacity: "1",
                        }
                    : {
                            paddingLeft: "0",
                            paddingRight: "0",
                            opacity: "0.5",
                        }
            }
        >
            {/* <WelcomeView 
                show={props.sidebar === "welcome"}
                navigate={props.navigate}
                goHome={props.goHome}
                shownSources={props.shownSources}
                setShownSources={props.setShownSources}
            /> */}
            <ViewView
                show={props.sidebar === "view"}
                setCenter={props.setCenter}
                setSidebar={props.setSidebar}
                setSidebarShown={props.setSidebarShown}
                navigate={props.navigate}
                event={props.loadedEvent}
                setHighlightedEvent={props.setHighlightedEvent}
            />
            <FindView
                show={props.sidebar === "find"}
                mapCenter={props.mapCenter}
                setCenter={props.setCenter}
                setSidebar={props.setSidebar}
                setSidebarShown={props.setSidebarShown}
                navigate={props.navigate}
                setHighlightedEvent={props.setHighlightedEvent}
                baseLocation={props.userLocation}
                setBaseLocation={props.setUserLocation}
                showFuture={false}
                showPast={false}
            />
            {/* <AddView
                show={props.sidebar === "add"}
                setSidebar={props.setSidebar}
                setSidebarShown={props.setSidebarShown}
                newEventPosition={props.newEventPosition}
                setNewEventPosition={props.setNewEventPosition}
            /> */}
            <AboutView
                show={props.sidebar === "about"}
                setSidebar={props.setSidebar}
                setSidebarShown={props.setSidebarShown}
                navigate={props.navigate}
            />
            <VerifyView
                show={props.sidebar === "verify"}
                setSidebar={props.setSidebar}
                setSidebarShown={props.setSidebarShown}
                navigate={props.navigate}
                centerMapAroundNewEvent={props.centerMapAroundNewEvent}
            />
            <NotFoundView
                show={props.sidebar === "404"}
                navigate={props.navigate}
            />
            <UnlockView
                show={props.sidebar === "unlock"}
                setSidebar={props.setSidebar}
                setSidebarShown={props.setSidebarShown}
                navigate={props.navigate}
            />
            <SourceView
                show={props.sidebar === "source"}
                source={props.loadedSource}
                setSidebarShown={props.setSidebarShown}
                navigate={props.navigate}
                setHighlightedEvent={props.setHighlightedEvent}
            />
            <PrefView
                show={props.sidebar === "prefs"}
                setHome={props.setHome}
                setSidebar={props.setSidebar}
                setSidebarShown={props.setSidebarShown}
                navigate={props.navigate}
            />
            {/* <SourcesView
                show={props.sidebar === "sources"}
                setSidebar={props.setSidebar}
                setSidebarShown={props.setSidebarShown}
            /> */}
            <WalletView
                show={props.sidebar === "wallet"}
                setSidebar={props.setSidebar}
                setSidebarShown={props.setSidebarShown}
                navigate={props.navigate}
            />
        </div>
    );
};
export default Sidebar;
