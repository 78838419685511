import React from "react";
import "../../index.css";
import DevNavbar from "../bars/DevNavbar";
import { Heading } from "react-bulma-components";
import { useTranslation } from "react-i18next";

const DevShell = () => {
    const { t } = useTranslation();
    
    const version = "0.0.1-buggy-atomic-bomb";
    const versions = ["0.0.1-buggy-atomic-bomb", "TODO-TODO-TODO"];
    return (
        <div className="has-navbar-fixed-top">
            <DevNavbar version={version} versions={versions} />

            <div
                className="container is-fluid has-background-light"
                style={{
                    padding: "2rem",
                    overflow: "scroll",
                    height: "calc(100vh - 3rem)",
                }}
            >
                <Heading>{t('developer-portal')}</Heading>
                <p className="notification is-warning">
                    TODO: Document several things
                    <ul style={{ listStyleType: "disc", marginLeft: "2rem" }}>
                        <li>APIs & model</li>
                        <li>sources</li>
                        <li>spawners</li>
                        <li>moderation and permissions</li>
                    </ul>
                </p>
                <br />
                <br />
                <Heading size={4}>Overview</Heading>
                <ul style={{ listStyleType: "disc", marginLeft: "2rem" }}>
                    <li>...</li>
                    <li>aim</li>
                    <li>future</li>
                    <li>licensing</li>
                    <li>legal responsibility & liability</li>
                </ul>
                <br />
                <br />
                <Heading size={4}>APIs</Heading>
                <p>...guide to endpoints</p>
                <br />
                <br />
                <Heading size={4}>Event Model</Heading>
                <ul style={{ listStyleType: "disc", marginLeft: "2rem" }}>
                    <li>what</li>
                    <ul style={{ listStyleType: "disc", marginLeft: "2rem" }}>
                        <li>name, desc</li>
                        <li>cats & hashtags: manual + auto tagger</li>
                        <li>custom hashtags? enjoy your irrelevance</li>
                    </ul>
                    <li>when</li>
                    <ul style={{ listStyleType: "disc", marginLeft: "2rem" }}>
                        <li>abs & rel times</li>
                        <li>moving things around</li>
                        <li>exceptional cases</li>
                    </ul>
                    <li>where</li>
                    <ul style={{ listStyleType: "disc", marginLeft: "2rem" }}>
                        <li>coords</li>
                        <li>places & addresses schema!! (use OSM?)</li>
                    </ul>
                    <li>who</li>
                    <li>meta</li>
                    <li>...serialization etc (json)</li>
                </ul>
                <br />
                <br />
                <Heading size={4}>Sources and Spawners</Heading>
                <ul style={{ listStyleType: "disc", marginLeft: "2rem" }}>
                    <li>spawner is the endpoint (think of ui)</li>
                    <li>spawner pushes to api endpoints</li>
                    <li>public spawners: foss scrapers</li>
                    <li>application for public source:</li>
                    <ul style={{ listStyleType: "disc", marginLeft: "2rem" }}>
                        <li>public utility</li>
                        <li>verifiability</li>
                        <li>quality of data</li>
                    </ul>
                    <li>privacy & encryption</li>
                    <li>how-to: coding simple spawner + `liborda`</li>
                    <li>relevance algo</li>
                </ul>
                <br />
                <br />
                <Heading size={5}>Boundaries and Permissions</Heading>
                <ul style={{ listStyleType: "disc", marginLeft: "2rem" }}>
                    <li>action square</li>
                    <li>max limits / caps per source</li>
                </ul>
                <br />
                <br />
                <Heading size={4}>Moderation</Heading>
                <ul style={{ listStyleType: "disc", marginLeft: "2rem" }}>
                    <li>user-based moderation</li>
                    <li>source authority & spam score</li>
                    <li>suspensions and bans of sources</li>
                    <li>reporting to authorities</li>
                </ul>
                <br />
                <br />
                <Heading size={5}>Publishing</Heading>
                <ul style={{ listStyleType: "disc", marginLeft: "2rem" }}>
                    <li>duplicate checking & "coverage" ("me too" / likely equivalents array)</li>
                    <li>aliasing / linkback ("retweet" like) of source events</li>
                </ul>

                <br />
                <br />
                <Heading size={4}>Support</Heading>
                <ul style={{ listStyleType: "disc", marginLeft: "2rem" }}>
                    <li>community</li>
                    <li>tech support</li>
                    <li>moderation</li>
                </ul>
            </div>
        </div>
    );
};

export default DevShell;
