/*
 * Timezones are a mess. These few support function try 
 * to make them a bit less messy.
 */

import { LatLngTuple } from "leaflet";

const TimezoneHelper = {
    getCapitalByTimezone(timezone: string): LatLngTuple {
        switch (timezone) {
            case "Europe/Paris":
                return [48.8566, 2.3522];
            case "Europe/Berlin":
                return [52.52, 13.405];
            case "Europe/Rome":
                return [41.9028, 12.4964];
            case "Europe/Madrid":
                return [40.4168, -3.7038];
            case "Europe/London":
                return [51.5074, -0.1278];
            case "Europe/Amsterdam":
                return [52.3667, 4.8945];
            case "Europe/Brussels":
                return [50.8503, 4.3517];
            case "Europe/Vienna":
                return [48.2082, 16.3738];
            case "Europe/Zurich":
                return [47.3769, 8.5417];
            case "Europe/Prague":
                return [50.0755, 14.4378];
            case "Europe/Helsinki":
                return [60.1699, 24.9384];
            case "Europe/Stockholm":
                return [59.3293, 18.0686];
            case "Europe/Athens":
                return [37.9838, 23.7275];
            case "America/New_York":
                return [40.7128, -74.006];
            case "America/Los_Angeles":
                return [34.0522, -118.2437];
            case "America/Chicago":
                return [41.8781, -87.6298];
            default:
                return [51.5074, -0.1278];
        }
    },
    getLocaleCapital(): LatLngTuple {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return TimezoneHelper.getCapitalByTimezone(timezone);
    },
};

export default TimezoneHelper;
