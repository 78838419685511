/*
 * Black card showing a result in the Find sidebar
 */
import React from "react";
import { Columns } from "react-bulma-components";

import "../../index.css";

const ResultBox = (props: any) => {
    return (
        <div
            className="box has-background-black-bis has-text-light"
            style={{ cursor: "pointer" }}
            onClick={props.clickHandler}
            // black bg on hover
            onMouseOut={(e: any) => {
                e.currentTarget.classList.replace(
                    "has-background-black",
                    "has-background-black-bis"
                );
                props.hoverOutHandler && props.hoverOutHandler();
            }}
            onMouseOver={(e: any) => {
                e.currentTarget.classList.replace(
                    "has-background-black-bis",
                    "has-background-black"
                );
                props.hoverHandler && props.hoverHandler();
            }}
        >
            <Columns>
                {props.leftChild && (
                    <Columns.Column size={2}>{props.leftChild}</Columns.Column>
                )}
                <Columns.Column style={{ maxWidth: "100%" }}>
                    <h3
                        className="title is-size-5 has-text-light"
                        style={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                        }}
                    >
                        {props.name}
                    </h3>
                    <p
                        className="subtitle is-size-7 has-text-light has-text-grey"
                        style={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            maxWidth: "100%",
                        }}
                    >
                        {props.line1 && (
                            <>
                                <span>{props.line1}</span>
                            </>
                        )}
                        {props.line2 && (
                            <>
                                <br />
                                <span className="has-text-grey">
                                    {props.line2}
                                </span>
                            </>
                        )}
                        {props.line3 && (
                            <>
                                <br />
                                <span className="has-text-grey">
                                    {props.line3}
                                </span>
                            </>
                        )}
                    </p>
                </Columns.Column>
            </Columns>
        </div>
    );
};

export default ResultBox;
