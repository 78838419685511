import React from "react";
import "../../index.css";
import { Navbar } from "react-bulma-components";
import Logo from "../widgets/Logo";

// Here, we display our Navbar
const GeoNavbar = (props: any) => {
    const [locationPermission, setLocationPermission] = React.useState(false);

    function getLocation() {
        if (navigator.geolocation) {
            navigator.permissions
                .query({ name: "geolocation" })
                .then((permissionStatus) => {
                    if (permissionStatus.state === "denied") {
                        setLocationPermission(false);
                    } else {
                        navigator.geolocation.getCurrentPosition(
                            (position) => setLocationPermission(true),
                            (error) => {}
                        );
                    }
                });
        } else {
            alert("Geolocation is not supported in your browser.");
        }
    }
    getLocation();
    // setInterval(getLocation, 1000);

    const NavbarItemWithIcon = (icon: string, target: string, title?: string, onClick?: any) => {
        return (
            <Navbar.Item
                className={ props.sidebar === target && props.sidebarShown ? "has-text-warning" : "" }
                onClick={ onClick || (() => props.navigate(target)) }
                title={title}
            >
                <i className={icon}></i>
            </Navbar.Item>
        );
    };

    return (
        <Navbar color="black" fixed="top" style={{ zIndex: 9999 }}>
            <Navbar.Menu backgroundColor="black" className="is-active">
                <Navbar.Container
                    align="left"
                    style={{ display: "inline-flex" }}
                >
                    {/* <Navbar.Item onClick={centerAndRefresh}>
                        here
                    </Navbar.Item> */}
                    <Navbar.Item
                        onClick={() => {
                            props.navigate("home");
                            props.goHome();
                        }}
                        title="locate me"
                    >
                        <i className="far fa-circle-dot"></i>
                    </Navbar.Item>
                    { !props.miniMode && NavbarItemWithIcon("fas fa-wallet", "wallet", "wallet") }
                    {/* { !props.miniMode && NavbarItemWithIcon("fas fa-door-open", "sources", "sources") } */}
                    {/* { !props.miniMode && NavbarItemWithIcon("fas fa-plus", "add", "add") } */}
                </Navbar.Container>
                <Navbar.Brand
                    style={{
                        display: "inline-flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Navbar.Item
                        onClick={() =>
                            props.navigate("about")
                        }
                        title="about orda"
                    >
                        <Logo />
                        { props.miniMode && <span className="is-size-7 has-text-warning mt-2">mini</span> }
                    </Navbar.Item>
                    {/* <Navbar.Burger onClick={() => setMenuOpen(!menuOpen)} className={`button navbar-burger ${menuOpen ? 'is-active' : ''}`} /> */}
                </Navbar.Brand>

                <Navbar.Container
                    align="right"
                    style={{ display: "inline-flex" }}
                >
                    {!locationPermission && (
                        <Navbar.Item
                            className="nav-buttons"
                            onClick={() =>
                                alert(
                                    "Either your browser doesn't support geolocation, or it was denied.\n\n" +
                                        "This permission is not strictly necessary, but it is very recommended because it would show things in your proximity first. We do not use this data to track you in any way."
                                )
                            }
                        >
                            <small className="has-text-warning is-size-7">
                                &#9888;&#65039; geolocation not allowed
                            </small>
                        </Navbar.Item>
                    )}
                    { !props.miniMode && NavbarItemWithIcon("fas fa-magnifying-glass", "find", "find") }
                    { !props.miniMode && NavbarItemWithIcon("fas fa-cog", "prefs", "settings") }

                    { props.miniMode && NavbarItemWithIcon("fas fa-unlock", "beta", "beta") }
                </Navbar.Container>
            </Navbar.Menu>
        </Navbar>
    );
};

export default GeoNavbar;
