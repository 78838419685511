import React from "react";
import "../../index.css";
import { Navbar } from "react-bulma-components";
import Logo from "../widgets/Logo";

// Here, we display our Navbar
const DevNavbar = (props: { version: string, versions?: string[] }) => {

    return (
        <Navbar fixed="top">
            <Navbar.Menu className="is-active">
                <Navbar.Container align="left">
                    <Navbar.Item>
                        <Logo />
                        &nbsp;&nbsp;
                        <span>Developer</span>
                    </Navbar.Item>
                </Navbar.Container>

                <Navbar.Container align="right">
                    <p className="navbar-item is-size-7 has-text-grey">
                        version:&nbsp;<a onClick={() => alert("TODO - switch versions")}>{props.version}</a>
                    </p>
                </Navbar.Container>
            </Navbar.Menu>
        </Navbar>
    );
};

export default DevNavbar;
