import { useState, useEffect } from "react";
import GeoNavbar from "../bars/GeoNavbar";
import "../../index.css";
import MapView from "../widgets/MapView";
import OrdaEvent from "../../model/OrdaEvent";
import TopicSwitcherBox from "../widgets/TopicSwitcherBox";
import LocalStorage from "../../utils/LocalStorage";
import { LatLngTuple } from "leaflet";
import { MainContextProvider, useMainContext } from "../../context/MainContext";
import { useParams } from "react-router-dom";
import ApiBox from "../../db/ApiBox";
import OrdaSource from "../../model/OrdaSource";
import Sidebar from "../views/Sidebar";
import { MapPositionState } from "../../utils/MapPositionState";
import InitialSetupModal from "../views/InitialSetupModal";
import GoToBetaModal from "../views/GoToBetaModal";
import supabaseToOrdaEvent from "../../model/OrdaEvent";
import WelcomeShell from "./WelcomeShell";

const MainShell = (props: {
    isFind?: boolean;
    isAbout?: boolean;
    isWallet?: boolean;
}) => {
    // Globals
    const REFRESH_SECONDS = 240;

    // TODO: make this a global setting
    const tiledMode = false;
    const miniMode = false;
    const { eventId, tagId, cityId, sourceId, objectKey } = useParams();

    // Navigation
    const navigate = (
        view: string,
        target: OrdaEvent | OrdaSource | string | null = null
    ) => {
        // set history
        switch (view) {
            case "beta":
                setGoToBeta(true);
                return;
            case "home":
                window.history.pushState({}, "Home", "/");
                setSidebarShown(false);
                setHighlightedEvent(undefined);
                // setCenter([0, 0]); // TODO: fly home!
                return;
            case "find":
                window.history.pushState({}, "Find", "/find");
                if (target !== null && (target as string).length > 0) {
                    console.log("TODO: search for " + target);
                }
                break;
            case "add":
                window.history.pushState({}, "Add", "/add");
                break;
            case "about":
                window.history.pushState({}, "About", "/about");
                break;
            case "verify":
                window.history.pushState({}, "Verify", "/verify");
                break;
            case "prefs":
                window.history.pushState({}, "Preferences", "/prefs");
                break;
            case "unlock":
                window.history.pushState({}, "Unlock", "/u/" + objectKey);
                break;
            case "404":
                window.history.pushState({}, "404", "/404");
                break;
            case "view":
                window.history.pushState(
                    {},
                    "View",
                    "/e/" + (target as OrdaEvent)._id
                );
                break;
            case "source":
                window.history.pushState(
                    {},
                    "Source",
                    "/s/" + (target as OrdaSource).id
                );
                if (target !== null && (target as OrdaSource)._id)
                    setLoadedSource(target as OrdaSource);
                else if (target !== null && (target as string).length > 0)
                    ApiBox.getSource(target as string)
                        .then((data: any) => {
                            if (data) {
                                setLoadedSource(data);
                            } else {
                                return navigate("404");
                            }
                        })
                        .catch((err: any) => {
                            navigate("404");
                        });
                else setLoadedSource(undefined);
                break;
            //default: window.history.pushState({}, "Home", "/"); break;
        }
        // reset map markers

        // clear sidebar if it's already shown
        // the "target" exception is to return to the main view chosen instead of closing the sidebar
        if (sidebarShown && view === sidebar && target === null) {
            setSidebarShown(false);
            window.history.pushState({}, "Home", "/");
            setHighlightedEvent(undefined);
            return;
        }

        if (view === "view" && target !== null && (target as OrdaEvent)._id) {
            const t = target as OrdaEvent;
            setCenter({
                coords: t.where[0].coords,
                flyAnimation: false,
                requestTimestamp: Date.now(),
            });
            setHighlightedEvent(t);
            setLoadedEvent(t);
        }

        // if(view === "add")
        //     map.setZoom(16);

        setSidebar(view);
        setSidebarShown(true);
    };

    // TODO: migrate all global-ish state to this context
    const { loading, saveLoading } = useMainContext();
    useEffect(() => {
        saveLoading(true);
    }, [loading]);
    const [sidebarShown, setSidebarShown] = useState(false);
    const [sidebar, setSidebar] = useState("find");
    const [auth, setAuth] = useState(LocalStorage.getPref('invite-token') ); // TODO: temporary fake door to keep out strangers

    // shown modals
    const [goToBeta, setGoToBeta] = useState(false);

    // Map center: settable (TODO: use MapPositionState!!)
    const [center, setCenter] = useState<MapPositionState>({
        coords: LocalStorage.getHome(),
        flyAnimation: false,
        requestTimestamp: Date.now(),
    });
    // The "actual center" is the center of the displayed map view (e.g. when the user drags the map around). We should never set this directly, MapWrapper does.
    const [actualCenter, setActualCenter] = useState<LatLngTuple>(
        center.coords
    );
    const [mapLastMoved, setMapLastMoved] = useState(0);
    const [highlightedEvent, setHighlightedEvent] = useState<
        OrdaEvent | undefined
    >(undefined);
    const [loadedEvent, setLoadedEvent] = useState<OrdaEvent | undefined>(
        undefined
    );
    const [loadedSource, setLoadedSource] = useState<any | undefined>(
        undefined
    );

    if (window.localStorage.getItem("home")) {
        //const coords = JSON.parse(window.localStorage.getItem("home") as string);
        //if(coords) setCenter(coords);
    }
    const [newEventPosition, setNewEventPosition] =
        useState<LatLngTuple | null>(null);
    // useEffect(() => {
    //     if(newEventPosition) setCenter(newEventPosition);
    // }, [newEventPosition]);

    useEffect(() => {
        // TODO: why does this section trigger infinite re-rendering?
        if (eventId) {
            ApiBox.getEvent(eventId)
                .then((data: any) => {
                    if (data) {
                        navigate("view", data as OrdaEvent);
                        // setCenter(data.where[0].coords);
                        // setCenter(data.where[0].coords);
                    } else {
                        navigate("404");
                    }
                })
                .catch((err: any) => {
                    navigate("404");
                });
        } else if (tagId) {
            console.log("TODO: fetch tag " + tagId);
            navigate("404");
        } else if (cityId) {
            console.log("TODO: fetch city " + cityId);
            navigate("404");
        } else if (sourceId) {
            ApiBox.getSource(sourceId)
                .then((data: any) => {
                    if (data) navigate("source", data);
                })
                .catch((err: any) => {
                    navigate("404");
                });
        } else if (objectKey) {
            console.log("TODO: fetch object " + objectKey);
            navigate("unlock");
        } else if (props.isFind) {
            navigate("find");
        } else if (props.isAbout) {
            navigate("about");
        } else if (props.isWallet) {
            navigate("wallet");
        } //else if ( LocalStorage.getSavedSources().length === 0 ) {
        // first time / empty map
        //    navigate("welcome");
        //}
    }, [eventId, tagId, cityId, sourceId, objectKey]); // TODO: trigger on []?

    // todo: set on EU/US capital based on ip with 30km range, default to london :)
    const [events, setEvents] = useState<OrdaEvent[]>([]);
    // bounds hack (remove from here and children)
    const [__visibleEventsInMapArea, __setVisibleEventsInMapArea] = useState<OrdaEvent[]>([]);
    const [__eventCountBySource, __setEventCountBySource] = useState<any>({});
    const [__eventCountByTopic, __setEventCountByTopic] = useState<any>({});
    // global source viewer stuff
    // use faRobot to show all automated spawners??
    let [zoom, setZoom] = useState<number>(0); // TODO: setZoom in map
    // !! TODO: interlink getSavedSources with this state !!
    const [shownSources, setShownSources] = useState<string[]>(
        LocalStorage.getSavedSources()
    );
    const [sources, setSources] = useState<any[]>([]);
    const [topics, setTopics] = useState<any[]>([]);
    const [shownTopic, setShownTopic] = useState<string | undefined>(undefined);

    // useEffect(() => {
    //     LocalStorage.setSavedSources(shownSources);
    // }, [shownSources]);

    // TODO: tidy and move somewhere else
    const getTopicsFromEvents = (events: any) => {
        const newTopics = new Map<string, Object>();
        events.forEach((event: OrdaEvent) => {
            event.what.tags.map((rawTag) => {
                const tag = rawTag.toLowerCase();
                // turn name into numeric hash...
                const tagHash = tag
                    .split("")
                    .reduce(
                        (acc: number, char: string) => acc + char.charCodeAt(0),
                        0
                    );
                // ...and use it to generate a color hash
                const colorHash = "hsl(" + (tagHash % 180) + ", 55%, 35%)";
                newTopics.set(tag, {
                    // TODO: id & name are same identifier
                    id: tag,
                    name: tag,
                    emoji: "#", // TODO: generate from some dictionary
                    color: colorHash,
                });
            });
        });

        return Array.from(newTopics.values());
    };

    // compute topics locally when fetching square of events (it's that chea
    useEffect(() => {
        //ApiBox.getEvents(...).then

        // topic format: {"id":"education","name":"education","emoji":"#","color":"hsl(56, 55%, 35%)"},
        // const topicsOnMap = ApiBox.getTopics(actualCenter[0], actualCenter[1], zoom, "").then((data: any) => { // TODO! deprecate this api call
        const data = getTopicsFromEvents(__visibleEventsInMapArea);
        // Show shownTopics first, otherwise sort by default
        const sortedTopics = data
            .filter(
                (s: any) =>
                    __eventCountByTopic[s.id] && __eventCountByTopic[s.id] > 0
            )
            .sort((a: any, b: any) => {
                // !!! TODO: sort by COUNT OF EVENTS SHOWN IN CURRENT MAP VIEW
                return __eventCountByTopic[b.id] - __eventCountByTopic[a.id];
            })
            .sort((a: any, b: any) => {
                // and put shown topics first
                return shownTopic === a.id ? -1 : 0;
            });
        setTopics(sortedTopics);

        // TODO: obsolete clause?
        if (
            !shownTopic ||
            sortedTopics.length === 0 ||
            !__eventCountByTopic[shownTopic]
        ) {
            setShownTopic(undefined);
        }
    }, [mapLastMoved, zoom]);

    // fetch events periodically
    useEffect(() => {
        const callback = async function () {
            await ApiBox.getEvents("").then((data: any) => {
                if (data) {
                    let events = data.map(supabaseToOrdaEvent);
                    setEvents(events);
                }
                //setLoading(false);
            });
        };
        const interval = setInterval(callback, REFRESH_SECONDS * 1000);
        callback();
        return () => clearInterval(interval);
    }, []);

    // HOME: place you saved to return to
    const setHome = () => {
        // NOT the "set" center, but the actual shown center of the map
        LocalStorage.setHome(actualCenter);
        alert(
            "You are now home. That is, your current position will be remembered when you open the app again."
        );
    };
    // LOCATION: place you return to if "home" is clicked but no home is set
    const [userLocation, setUserLocation] = useState<LatLngTuple | null>(null);

    // TODO / semantics: better go to cur location than pre-set home, right?
    // TODO / semantics: fix flight to home by decoupling *cur* map position and *target* map position
    //                      -> in this case, meta-params (timestamp, flyAnimation?, setZoom?, etc) should be stored in the state too to avoid e.g. rerenderings
    const goHome = () => {
        if (userLocation) {
            setCenter({
                coords: userLocation,
                flyAnimation: false,
                requestTimestamp: Date.now(),
            });
        } else {
            setCenter({
                coords: LocalStorage.getHome(),
                flyAnimation: false,
                requestTimestamp: Date.now(),
            });
        }
    };

    const centerMapAroundNewEvent = () => {
        if (newEventPosition !== null) {
            // fly to new event
            //setCenter(newEventPosition);
        }
    };
    return !auth ? <WelcomeShell auth setAuth /> : (
        <MainContextProvider>
            <div className="has-navbar-fixed-top has-background-dark">
                {/* Navbar */}
                <GeoNavbar
                    sidebar={sidebar}
                    sidebarShown={sidebarShown}
                    navigate={navigate}
                    setCenter={setCenter}
                    goHome={goHome}
                    miniMode={miniMode}
                />

                {/* Center view */}
                <div
                    id="mainbox"
                    className={
                        (tiledMode ? "tiled tiled-padded" : "") +
                        " " +
                        (!sidebarShown ? "sidebar-hidden" : "")
                    }
                >
                    <MapView
                        events={events}
                        mapLastMoved={mapLastMoved}
                        setMapLastMoved={setMapLastMoved}
                        highlightedEvent={highlightedEvent}
                        setHighlightedEvent={setHighlightedEvent}
                        loadedEvent={loadedEvent}
                        setLoadedEvent={setLoadedEvent}
                        navigate={navigate}
                        center={center}
                        setCenter={setCenter}
                        setActualCenter={setActualCenter}
                        newEventPosition={newEventPosition}
                        setNewEventPosition={setNewEventPosition}
                        sidebar={sidebar}
                        sidebarShown={sidebarShown}
                        setSidebarShown={setSidebarShown}
                        setUserLocation={setUserLocation}
                        showFuture={true}
                        showPast={false}
                        shownSources={shownSources}
                        shownTopics={shownTopic ? [shownTopic] : undefined}
                        __setVisibleEventsInMapArea={
                            __setVisibleEventsInMapArea
                        }
                        __setEventCountBySource={__setEventCountBySource}
                        __setEventCountByTopic={__setEventCountByTopic}
                    />
                </div>

                {/* Sidebar overlay */}
                <Sidebar
                    tiledMode={tiledMode}
                    sidebar={sidebar}
                    setSidebar={setSidebar}
                    sidebarShown={sidebarShown}
                    setSidebarShown={setSidebarShown}
                    navigate={navigate}
                    highlightedEvent={highlightedEvent}
                    setHighlightedEvent={setHighlightedEvent}
                    loadedEvent={loadedEvent}
                    setLoadedEvent={setLoadedEvent}
                    loadedSource={loadedSource}
                    setLoadedSource={setLoadedSource}
                    setCenter={setCenter}
                    newEventPosition={newEventPosition}
                    setNewEventPosition={setNewEventPosition}
                    setHome={setHome}
                    goHome={goHome}
                    centerMapAroundNewEvent={centerMapAroundNewEvent}
                    userLocation={userLocation}
                    setUserLocation={setUserLocation}
                    shownSources={shownSources}
                    setShownSources={setShownSources}
                    mapCenter={actualCenter}
                />

                {/* Floating source list */}
                <TopicSwitcherBox
                    topics={topics}
                    setTopics={setTopics}
                    shown={shownTopic}
                    setShown={setShownTopic}
                />

                {/* Beta Modal */}
                <GoToBetaModal
                    show={goToBeta}
                    setShow={setGoToBeta}
                    navigate={navigate}
                />
            </div>
        </MainContextProvider>
    );
};

export default MainShell;
