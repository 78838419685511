/*
 * List of topics/tags/... that can be toggled on and off.
 * !!! TODO: replace fontawesome and icon passed with pure emoji view with fallback on fa-hashtag for undefined tags!
 */
import React from "react";

const TopicSwitcher = (props: {
    topics: any;
    shown?: string;
    setShown: (id?: string) => void;
    linkMode: boolean;
    navigate: (view: string, target: string) => void;
    showIcon?: boolean;
    showName?: boolean;
}) => {
    // is this the right way to do this?
    let showIcon = props.showIcon === undefined ? true : props.showIcon;
    let showName = props.showName === undefined ? true : props.showName;

    // HACK! remove duplicates
    const topics = props.topics.filter(
        (s: any, i: number, self: any) =>
            self.findIndex((t: any) => t.id === s.id) === i
    );

    const toggleShown = (id: string) => {
        if (props.shown !== id) props.setShown(id)
        else props.setShown(undefined);
    };
    return (
        <div
            className="evt-categories"
            style={{
                whiteSpace: "nowrap",
                overflowX: "auto",
                margin: "0.5em 0.5em 0 0 ",
                padding: "0 0 0 0.5em",
            }}
        >
            {topics.length === 0 && (
                <p className="button is-small is-dark has-tooltip-bottom tag is-rounded has-background-black is-medium">
                    <i className="fas fa-ghost fa-fade fa-1.5x"></i>
                </p>
            )}

            {topics.map((s: any) => {
                let color = (
                    props.linkMode || props.shown === undefined || props.shown === s.id
                ) ? s.color : "hsl(0, 0%, 14%)";
                return (
                    <button
                        className={`button is-small is-dark has-tooltip-bottom tag is-rounded is-medium`}
                        style={{
                            backgroundColor: color,
                            padding: "0.7em",
                            marginRight: "0.4em",
                        }}
                        key={s.id}
                        data-tooltip={s.name}
                        title={s.name}
                        onClick={
                            props.linkMode
                                ? () => {
                                      props.navigate("find", s.id);
                                  }
                                : () => toggleShown(s.id)
                        }
                    >
                        {!showIcon ? ( // TODO: is icon or emoji better? || TODO: fallback on fa-hashtag when no emoji!
                            <i className={`fas ${s.icon} has-text-white`}></i>
                        ) : (
                            s.emoji
                        )}
                        {showName && (
                            <small style={{ fontSize: "0.7em" }}>
                                &nbsp; {s.id}
                            </small>
                        )}
                    </button>
                );
            })}
        </div>
    );
};
export default TopicSwitcher;
