/* home, cal alerts */
import React from "react";
import { Button, Columns, Heading } from "react-bulma-components";
import "../../index.css";
import SourceSwitcher from "../widgets/SourceSwitcher";
import moment from "moment";
import SidebarNavigator from "../widgets/SidebarNavigator";
import SourceTag from "../widgets/SourceTag";

const ViewView = (props: any) => {
    const { what, when, where, who, how, meta, _id } = props.event || {
        what: null,
        when: null,
        where: null,
        who: null,
        how: null,
        meta: null,
        _id: null,
    };

    const metresAwayFromUserPosition = (coords: any) => {
        return Math.random() * 100000;
    };
    const extractHostname = (url: string) => {
        var hostname;
        //find & remove protocol (http, ftp, etc.) and get hostname

        if (url.indexOf("//") > -1) {
            hostname = url.split("/")[2];
        } else {
            hostname = url.split("/")[0];
        }

        //find & remove port number
        hostname = hostname.split(":")[0];
        //find & remove "?"
        hostname = hostname.split("?")[0];
        //find & remove "www."
        hostname = hostname.replace("www.", "");

        return hostname;
    }

    /* Sanitize HTML to plain text */
    const parseDescription = (description: string) => {
        return description
            .replace(/<br\s*\/?>/gi, "\n")
            .replace(/<\/p>/gi, "\n")
            .replace(/<a.*?href="(.*?)".*?>(.*?)<\/a>/gi, "[url]")
            .replace(/https?:\/\/\S+/gi, "[url]")
            .replace(/<[^>]*>?/gm, '');
    }

    const isFuture = (date: Date) => {
        return moment(date).isAfter(moment());
    }
    const isPast = (date: Date) => {
        return moment(date).isBefore(moment());
    }


    return props.event ? (
        <div
            className="side-entry has-text-light"
            style={{ display: props.show ? "block" : "none" }}
        >
            <div>
                <SidebarNavigator
                    back={() => {
                        props.navigate("find");
                        props.setHighlightedEvent(undefined);
                    }}
                    close={() => {
                        props.navigate("home");
                        props.setHighlightedEvent(undefined);
                    }}
                />

                <br />
                <br />
                {/* copy event id to clipboard on click */}
                <Heading
                    className="title is-4 has-text-white"
                    onClick={() => {
                        navigator.clipboard.writeText(
                            "https://orda.zone/e/" + _id
                        );
                        alert("Copied event link to clipboard!");
                    }}
                    style={{ cursor: "pointer" }}
                >
                    {what.name}
                </Heading>
                {/* <div className="is-pulled-right">
                    <i
                        className="far fa-star has-text-warning"
                        onClick={() => alert("TODO: save in redux")}
                    ></i>
                </div> */}
                <p className="has-text-grey-light is-size-6">
                    view on &nbsp;
                    <a
                        href={meta.source.href}
                        className="tage is-outlined has-backgrdound-light has-text-link"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <i className="fas fa-link" />&nbsp; <b>{ extractHostname(meta.source.href) }</b>
                    </a> 
                    &nbsp;{" "}&mdash;{" "}
                    via {" "}
                    {/* !!! TODO use navigate() + fetch() here? , TODO: move to separate consistent widget */}
                    <SourceTag 
                        navigate={props.navigate} 
                        sourceName={meta.spawner}
                        sourceId={meta.spawner}
                        verified={false}
                    />
                    {/* &nbsp; on {moment(meta.created).format("DD.MM.YY, HH:mm")} */}
                </p>
                <hr className="has-background-grey-dark" />
                <Columns>
                    <Columns.Column>
                        <p className="has-text-primary-dark">
                            {/* TODO: use color code and diamond as in map */}
                            <i className="fa-solid fa-clock has-text-primary-dark mr-2"></i>
                            { isFuture(when[0].start) && !isPast(when[0].end) && <>
                                Starts{" "}
                                {moment(when[0].start)
                                    .fromNow()
                                    .replace("a few", "")}
                            </>}
                            { isPast(when[0].start) && <>
                                Ends{" "}
                                {moment(when[0].end)
                                    .fromNow()
                                    .replace("a few", "")}
                            </>}
                        </p>
                            
                        Lasts{" "}
                        {moment(when[0].end).diff(
                            moment(when[0].start),
                            "days"
                        ) > 1
                            ? moment(when[0].end).diff(
                                  moment(when[0].start),
                                  "days"
                              ) + " days"
                            : moment(when[0].end).diff(
                                  moment(when[0].start),
                                  "hours"
                              ) + " hours"}
                        <br />
                        {moment(when[0].start).format("dd DD.MM")},{" "}
                        <b>{moment(when[0].start).format("HH:mm a")}</b>
                        &nbsp; &ndash;
                        <br />
                        {moment(when[0].end).format("dd DD.MM")},{" "}
                        <b>{moment(when[0].end).format("HH:mm a")}</b>
                        <br />
                    </Columns.Column>
                    <Columns.Column>
                        <p className="has-text-primary-dark">
                            <i className="fa-solid fa-location-dot mr-2"></i>
                            {metresAwayFromUserPosition(where[0].pos) > 1000 ? (
                                <span>
                                    {Math.round(
                                        metresAwayFromUserPosition(
                                            where[0].pos
                                        ) / 1000
                                    )}{" "}
                                    km away
                                </span>
                            ) : (
                                <span>
                                    {Math.round(
                                        metresAwayFromUserPosition(
                                            where[0].pos
                                        )
                                    )}{" "}
                                    metres away from your position
                                </span>
                            )}
                        </p>
                        TODO rewrite this well
                        {/* <b>{where[0].placeName}</b> <br />
                        <i>{where[0].address}</i> <br />
                        {where[0].zip} {where[0].city}, {where[0].country} */}
                    </Columns.Column>
                </Columns>
                {/* <div className="tags">
                    <div className="tag is-black is-rounded">
                        #mocktag
                    </div>
                    <div className="tag is-black is-rounded">
                        #letssaythisisahashtag
                    </div>
                    <div className="tag is-black is-rounded">
                        #xrco
                    </div>
                    <div className="tag is-black is-rounded">
                        #callthisisahashtag
                    </div>
                    <div className="tag is-black is-rounded">
                        #extinctionrebellion
                    </div>
                </div> */}
                coverage by
                <SourceSwitcher 
                    navigate={props.navigate} 
                    showIcon={true} 
                    showName={true} 
                    linkMode={true} 
                    sources={[{
                        id: meta.spawner,
                        name: meta.spawner,
                        icon: "fa-satellite-dish",
                        emoji: "📡",
                        color: "#cc0f35"
                    }].concat(what.tags.map((tag: string) => ({
                        id: tag,
                        name: tag,
                        icon: "hashtag",
                        emoji: "#",
                        color: "black"
                    })))}
                />
                <br />
                <br />
                <br />
                <p
                    className="has-text-grey-light"
                    style={{ whiteSpace: "pre-wrap" }}
                > 
                    { parseDescription(what.description) }
                </p>
                
                {/* TODO!! <TicketBox /> */}
                <br />
                <br />
                <small className="has-text-grey">
                    <i className="fa-solid fa-info-circle mr-2"></i>
                    <b>Event ID:</b> {_id}
                </small>
                <div className="is-pulled-right">
                    <Button
                        color="primary"
                        size="small"
                        outlined
                        rounded
                        onClick={() => {
                            if (navigator.share) {
                                navigator.share({
                                    title: "orda.zone event",
                                    text: what.name,
                                    url: "https://orda.zone/e/" + _id,
                                });
                            } else if (navigator.clipboard) {
                                navigator.clipboard.writeText(
                                    "https://orda.zone/e/" + _id
                                );
                                alert("Copied event link to clipboard!");
                            }
                        }}
                    >
                        share
                    </Button>
                    &nbsp;&nbsp;
                    {/* <Button className="is-small is-primary" onClick={() => props.navigate("add")} size="small" outlined>
                        add to calendar
                    </Button> */}
                    &nbsp;&nbsp;
                    <Button
                        color="primary"
                        size="small"
                        onClick={() => {
                            props.navigate("report");
                            props.setHighlightedEvent(undefined);
                        }}
                        outlined
                        rounded
                    >
                        report
                    </Button>
                </div>
                <br />
                <br />
                <br />
                <br />
            </div>
        </div>
    ) : (
        <div
            className="side-entry has-text-light"
            style={{
                display: props.show ? "flex" : "none",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            Boo!
        </div>
    ); // TODO: fix this, add proper empty state
};
export default ViewView;
