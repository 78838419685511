/* home, cal alerts */
import React from "react";
import OrdaEvent from "../../model/OrdaEvent";
import TopicSwitcher from "../widgets/TopicSwitcher";
import ResultBox from "../widgets/ResultBox";
import { Columns, Heading } from "react-bulma-components";
import ApiBox from "../../db/ApiBox";
import { enabledCities } from "../../utils/Cities";
import moment from "moment";
import { MapPositionState } from "../../utils/MapPositionState";
import SidebarNavigator from "../widgets/SidebarNavigator";

const FindView = (props: any) => {
    type City = {
        name: string;
        country: string;
        coords: [number, number];
    };

    // TODO: show relevant sources (as from map viewer) in this widget??
    const sources = [
        {
            id: "LMU_official",
            name: "LMU Events",
            icon: "fa-university",
            color: "primary",
        },
    ];
    const [shown, setShown] = React.useState<string[]>([]); // ["LMU_official"
    const [loading, setLoading] = React.useState(true);
    const [query, setQuery] = React.useState("");
    const [rawResults, setRawResults] = React.useState<OrdaEvent[]>([]);
    const [results, setResults] = React.useState<OrdaEvent[]>([]);
    const [resultCities, setResultCities] = React.useState<City[]>([]);
    const [resultSources, setResultSources] = React.useState<any[]>([]);
    const [isExpanded, setIsExpanded] = React.useState({
        cities: false,
        sources: false,
        events: false,
    });
    // const [resultSources, setResultSources] = React.useState<any[]>([]);
    // const [resultTags, setResultTags] = React.useState<any[]>([]);

    React.useEffect(() => {
        setLoading(true);
        ApiBox.getEvents(query).then((res: any) => {
            setRawResults(res);
            setLoading(false);
        });

        setResultCities(
            enabledCities.filter((city) =>
                city.name.toLowerCase().includes(query.toLowerCase())
            )
        );

        ApiBox.getSources(0, 0, 0, query).then((res: any) => {
            setResultSources(res);
        });
    }, [query]);

    React.useEffect(() => {
        // don't show past or future unless props.showPast or props.showFuture - otherwise show just live events (happening rn)
        setResults(
            rawResults
                .filter((event: OrdaEvent) => {
                    return (
                        (props.showPast ||
                            moment(event.when[0].end).isAfter(moment())) &&
                        (props.showFuture || moment(event.when[0].start).isBefore(moment().add(1, 'day'))) // always show near future events
                    );
                })
                // TODO : show only visible tag ones or all?
                // TODO: sort by distance from center of visible map
                .sort((a: OrdaEvent, b: OrdaEvent) => {
                    // sort by distance from props.userLocation
                    if (props.mapCenter) {
                        const aDist = Math.sqrt(
                            Math.pow(
                                a.where[0].coords[0] - props.mapCenter.lat,
                                2
                            ) +
                                Math.pow(
                                    a.where[0].coords[1] -
                                        props.mapCenter.lng,
                                    2
                                )
                        );
                        const bDist = Math.sqrt(
                            Math.pow(
                                b.where[0].coords[0] - props.mapCenter.lat,
                                2
                            ) +
                                Math.pow(
                                    b.where[0].coords[1] -
                                        props.mapCenter.lng,
                                    2
                                )
                        );
                        const distanceScale = aDist - bDist;
                        // send long-running events to the bottom
                        const longRunningA = moment(a.when[0].end).diff(moment(a.when[0].start)) > 1000 * 60 * 60 * 24;
                        const longRunningB = moment(b.when[0].end).diff(moment(b.when[0].start)) > 1000 * 60 * 60 * 24;
                        const durationScale = longRunningA ? 10 : longRunningB ? -10 : 0;

                        return distanceScale + durationScale;
                    } else {
                        return moment(a.when[0].start).diff(
                            moment(b.when[0].start)
                        );
                    }
                })

        );
    }, [rawResults, props.showPast, props.showFuture, props.mapCenter]);

    const countryToEmoji = (country: string) => {
        switch (country) {
            case "Germany":
                return "🇩🇪";
            case "United States":
                return "🇺🇸";
            case "USA":
                return "🇺🇸";
            case "Canada":
                return "🇨🇦";
            case "United Kingdom":
                return "🇬🇧";
            case "UK":
                return "🇬🇧";
            case "France":
                return "🇫🇷";
            case "Italy":
                return "🇮🇹";
            case "Finland":
                return "🇫🇮";
            default:
                return "🌍";
        }
    };

    const noHtml = (str?: string) => {
        return str && str.replace(/<[^>]*>?/gm, "");
    }

    const Collapsible = (props: {
        totalResults: number;
        isExpanded: boolean;
        onExpand: () => void;
        onCollapse: () => void;
        limit: number;
    }) => {
        return (
            <div
                className="is-pulled-right has-text-grey-light"
                style={{ cursor: "pointer", display: props.totalResults > props.limit ? "block" : "none" }}
                onClick={() => props.isExpanded ? props.onCollapse() : props.onExpand()}
            >
                { !props.isExpanded
                    ? "+" + (props.totalResults - props.limit) +" more..."
                    : "(show less)"
                }
            </div>
        );
    };

    return (
        <div
            className="side-entry"
            id="side-entry-find"
            style={{ display: props.show ? "block" : "none" }}
        >
            <SidebarNavigator
                close={() => props.navigate("home")}
            />
            <br /><br />
            <div id="event-find">
                <form className="box has-background-black has-text-white">
                    {loading && (
                        <i className="fa-solid fa-circle-notch fa-spin has-text-warning"></i>
                    )}
                    {!loading && (
                        <i className="fa-solid fa-search has-text-warning"></i>
                    )}
                    &nbsp;&nbsp;
                    <input
                        type="search"
                        className="modest-input title is-4 has-text-light has-background-black"
                        placeholder="look for..."
                        value={query}
                        onChange={(e: any) => setQuery(e.target.value)}
                        style={{ width: "90%" }}
                        autoFocus
                        autoComplete="none"
                        onFocus={(e: any) => e.target.select()}
                    />
                </form>
                <br />

                <Collapsible
                    limit={3}
                    totalResults={results.length}
                    isExpanded={isExpanded.events}
                    onExpand={() =>   setIsExpanded({ ...isExpanded, events: true })}
                    onCollapse={() => setIsExpanded({ ...isExpanded, events: false })}
                />
                <Heading className="has-text-warning" size={5}>
                    live now
                </Heading>
                {results
                    .slice(0, isExpanded.events ? results.length : 3)
                    .map((result) => {
                        return (
                            <ResultBox
                                key={result.what + result._id}
                                clickHandler={() =>
                                    props.navigate("view", result)
                                }
                                // hoverHandler={() => {
                                //     props.setHighlightedEvent(result);
                                //     props.setCenter({ coords: result.where[0].coords, flyAnimation: false, requestTimestamp: Date.now() } as MapPositionState);
                                // }}
                                // hoverOutHandler={
                                //     () =>
                                //         // TODO: is this good or too heavy?
                                //         props.setHighlightedEvent(undefined)
                                // }
                                name={result.what.name}
                                navigate={props.navigate}
                                image={result.what.image}
                                line2={
                                    <span className="has-text-primary">
                                        {result.what.tags.map((tag) => {
                                            return (
                                                <span
                                                    key={tag}
                                                    className="has-text-primary"
                                                    style={{
                                                        marginRight: "0.5em",
                                                    }}
                                                >
                                                    #{tag}
                                                </span>
                                            );
                                        })}
                                    </span>
                                }
                                line3={<span>
                                    <br />
                                    via  @{result.meta.spawner}, starting {" "}
                                    <span className={moment(result.when[0].start).isAfter(moment()) ? "has-text-success" : "has-text-warning"}>
                                    {moment(result.when[0].start)
                                        .fromNow()
                                        .replace("a few", "")}
                                    </span>
                                </span>}
                                line1={noHtml(result.what.description?.slice(0, 100))}

                                //line2={result.what.description!.slice(0, 100)}
                            />
                        );
                        // return (
                        //     <div
                        //         style={{ cursor: "pointer" }}
                        //         className="box has-background-black-bis has-text-light"
                        //         key={result.what.name + result.when[0].start}
                        //         onClick={() => props.navigate("view", result)}
                        //     >
                        //         <h3
                        //             className="title is-size-5 has-text-light"
                        //             style={{
                        //                 textOverflow: "ellipsis",
                        //                 overflow: "hidden",
                        //                 whiteSpace: "nowrap",
                        //             }}
                        //         >
                        //             {result.what.name}
                        //         </h3>
                        //         <p className="subtitle is-size-7 has-text-light">
                        //             <i className="fa-solid fa-map-marker-alt has-text-warning"></i>
                        //             &nbsp;&nbsp;
                        //             <span className="has-text-light">
                        //                 {result.where[0].coords[0] +
                        //                     ", " +
                        //                     result.where[0].coords[1]}
                        //             </span>
                        //             <i
                        //                 className="fa-solid fa-clock has-text-warning"
                        //                 style={{ marginLeft: "1em" }}
                        //             ></i>
                        //             &nbsp;&nbsp;
                        //             <span className="has-text-light">
                        //                 Starts in 2 hours
                        //             </span>
                        //         </p>
                        //         <TopicSwitcher
                        //             showIcon={true}
                        //             showName={false}
                        //             sources={[]}
                        //         />
                        //     </div>
                        // );
                    })}
                <br />
                <br />

                <Collapsible 
                    limit={4}
                    totalResults={resultCities.length}
                    isExpanded={isExpanded.cities}
                    onExpand={() =>   setIsExpanded({ ...isExpanded, cities: true })}
                    onCollapse={() => setIsExpanded({ ...isExpanded, cities: false })}
                />
                <Heading className="has-text-warning" size={5}>
                    cities
                </Heading>
                <Columns>
                {resultCities
                    .slice(0, isExpanded.cities ? resultCities.length : 4)
                    .map((result) => {
                        return (
                            <Columns.Column size={6}>
                                <ResultBox
                                    key={result.name}
                                    leftChild={
                                        <span className="is-size-3 has-text-warning-light">
                                            {countryToEmoji(result.country)}
                                        </span>
                                    }
                                    name={result.name}
                                    navigate={props.navigate}
                                    line1={result.country}
                                    line2="~20 events right now"
                                    clickHandler={() => {
                                        props.setSidebarShown(false);
                                        props.setCenter({ coords: result.coords, flyAnimation: false, requestTimestamp: Date.now() } as MapPositionState);
                                    }}
                                />
                            </Columns.Column>
                        );
                    })}
                </Columns>
                <br />
                <br />

                <Collapsible
                    limit={3}
                    totalResults={resultSources.length}
                    isExpanded={isExpanded.sources}
                    onExpand={() =>   setIsExpanded({ ...isExpanded, sources: true })}
                    onCollapse={() => setIsExpanded({ ...isExpanded, sources: false })}
                />
                <Heading className="has-text-warning" size={5}>
                    sources
                </Heading>
                {resultSources
                    .slice(0, isExpanded.sources ? resultSources.length : 3)
                    .map((result) => {
                        return (
                            <ResultBox
                                key={result._id}
                                name={result.name}
                                navigate={props.navigate}
                                leftChild={
                                    <i className="fas fa-satellite-dish fa-2x has-text-grey" />
                                }
                                line1={"@" + result.id}
                                line2="~20 events per month"
                                clickHandler={() =>
                                    props.navigate("source", result)
                                }
                            />
                        );
                    })}
                {/* <ResultBox name="LMU Events" navigate={props.navigate} location="@LMU_official" time="~20 events per month" />
                <ResultBox name="City of Munich / Stadt München" navigate={props.navigate} location="@muenchen.de" time="~20 events per month" />
                <ResultBox name="Munich Meetups" navigate={props.navigate} location="@Meetup/MUC" time="~20 events per month" /> */}
                <br />
                <br />
            </div>
        </div>
    );
};
export default FindView;
