/*
 * Utilities for backend APIs
 */

import axios, { AxiosResponse } from "axios";

interface Event {
    id: string;
    name: string;
    latitude: number;
    longitude: number;
}

const ApiBox = {
    getEvents: async (query: string): Promise<AxiosResponse<Event[]>> => {
        const encodedQuery = encodeURIComponent(query);
        return axios
            .get(`/events?q=${encodedQuery}`)
            .then((response) => response.data)
            .catch(() => {
                throw new Error("Error retrieving events");
            });
    },

    getEventsByLocation: async (
        latitude: number,
        longitude: number
    ): Promise<AxiosResponse<Event[]>> => {
        return axios
            .get(`/events?latitude=${latitude}&longitude=${longitude}`)
            .then((response) => response.data)
            .catch(() => {
                throw new Error("Error retrieving events");
            });
    },

    getEvent: async (id: string): Promise<AxiosResponse<Event>> => {
        return axios
            .get(`/events/${id}`)
            .then((response) => response.data)
            .catch(() => {
                throw new Error("Error retrieving event");
            });
    },

    getSources: async (
        centerLat: number,
        centerLon: number,
        zoom: number,
        query: string,
        limit?: number
    ): Promise<AxiosResponse<any[]>> => {
        return axios
            .get(`/sources/${centerLat};${centerLon}@${zoom}/?q=${query}&limit=${limit}`)
            .then((response) => response.data)
            .catch(() => {
                throw new Error("Error retrieving sources");
            });
    },

    getSource: async (id: string): Promise<AxiosResponse<any>> => {
        return axios
            .get(`/source/${id}`)
            .then((response) => response.data)
            .catch(() => {
                throw new Error("Error retrieving source");
            });
    },


    getTopics: async (
        centerLat: number,
        centerLon: number,
        zoom: number,
        query: string,
        limit?: number
    ): Promise<AxiosResponse<any[]>> => {
        return axios
            .get(`/topics/${centerLat};${centerLon}@${zoom}/?q=${query}&limit=${limit}`)
            .then((response) => response.data)
            .catch(() => {
                throw new Error("Error retrieving topics");
            });
    },
};

export default ApiBox;
