/*
 * orda.zone Event
 */

export interface GEWhat {
    name: string;
    description?: string;
    tags: string[];
    image?: string;
    links: {
        name: string;
        href: string;
    }[];
}

export interface GEWhen {
    fragment?: string;
    start: number; // unix timestamp
    end: number;
    type?: string; // absolute, recurring... -> absolute only atm
}

export interface GEWhere {
    placeName?: string;
    coords: [number, number];
    //altitude: string; // lol
    city?: string;
    address?: string;
    zip?: string;
    country: string;
}

export interface GEWho {
    name: string;
    email?: string;
    phone?: string;
    url?: string;
    type?: string; // entity, person, group, organization, performer, sponsor, ...
}

export interface GEHow {
    // Tickets, admission, etc.
    admission: {
        name?: string;
        description?: string;
        price: number;
        privilege?: string; // open, closed, secret, limited to members, etc.
        indications?: string; // for example: "only for members"
        limitations?: string; // for example: "no smoking / no pets"
    }[];
}

export interface GEMeta {
    slug: string;
    source: {
        name: string;
        href: string;
    };
    created: number;
    updated: number;
    version: string; // of schema
    //license: string; // ??
    spawner?: string; // script name
    history?: any[];
}


export default interface OrdaEvent {
    // Informations about what the event actually is
    what: GEWhat;

    // When the event will take place in time
    when: GEWhen[];

    // Where the event is happening in space
    where: GEWhere[];

    // Who is involved in organizing the event
    who: GEWho[];

    // How you can take part in the event
    how: GEHow;

    // General data for orda.zone itself
    meta: GEMeta;

    _id: string;
}

// !! TODO: DEPRECATED - get rid of this
export default function supabaseToOrdaEvent(event: any): any {
    // TODO: fix type to OrdaEvent
    return {
        _id: event["_id"],
        what: {
            name: event["what"]["name"],
            description: event["what"]["description"],
            tags: event["what"]["tags"],
            image: event["what"]["image"],
            links: event["what"]["links"],
        },
        when: event["when"],
        where: event["where"],
        who: event["who"],
        how: event["how"],
        meta: {
            slug: event["meta"]["slug"],
            source: event["meta"]["source"],
            created: event["meta"]["created"],
            updated: event["meta"]["updated"],
            version: event["meta"]["version"],
            spawner: event["meta"]["spawner"],
        },
    };
}
