/*
 * Hardcoded list of enabled urban areas
 * This changes so rarely it doesn't deserve a REST API call
 */
export const enabledCities = [
    {
        name: "Berlin",
        country: "Germany",
        coords: [52.520008, 13.404954] as [number, number],
    },
    {
        name: "Munich",
        country: "Germany",
        coords: [48.137154, 11.576124] as [number, number],
    },
    // { name: "Hamburg", country: "Germany", coords: [53.551086, 9.993682] as [number, number] },
    // { name: "New York", country: "United States", coords: [40.712776, -74.005974] as [number, number] },
    // {
    //     name: "Pittsburgh",
    //     country: "United States",
    //     coords: [40.440624, -79.995888] as [number, number],
    // },
    // { name: "San Francisco", country: "United States", coords: [37.774929, -122.419418] as [number, number] },
    {
        name: "London",
        country: "United Kingdom",
        coords: [51.507351, -0.127758] as [number, number],
    },
    {
        name: "Helsinki",
        country: "Finland",
        coords: [60.169857, 24.938379] as [number, number],
    },
    // {
    //     name: "Paris",
    //     country: "France",
    //     coords: [48.856613, 2.352222] as [number, number],
    // },
    {
        name: "Trento",
        country: "Italy",
        coords: [46.066422, 11.12576] as [number, number],
    },
    // { name: "Rome", country: "Italy", coords: [41.902782, 12.496366] as [number, number] },
    {
        name: "Rovereto",
        country: "Italy",
        coords: [45.8904000, 11.0405300] as [number, number],
    },
];
