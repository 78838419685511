import React from "react";
import { Heading } from "react-bulma-components";
import SnitchBar from "../widgets/SnitchBar";
import SidebarNavigator from "../widgets/SidebarNavigator";
import SourceTag from "../widgets/SourceTag";
import SourceBox from "../widgets/SourceBox";
import moment from "moment";

const SourceView = (props: { source: any; navigate: any; show: boolean; setSidebarShown: any; setHighlightedEvent: any }) => {
    return props.source ? (
        <div
            className="side-entry has-text-light"
            style={{ display: props.show ? "block" : "none" }}
        >
            <SidebarNavigator
                back={() => {
                    props.navigate("find");
                    props.setHighlightedEvent(undefined);
                }}
                close={() => {
                    props.navigate("home")
                    props.setHighlightedEvent(undefined);
                }}
            />
            <br />
            <br />

            <div>
                <Heading size={4} className="has-text-warning">
                    {props.source.name}
                </Heading>
                { /* TODO: clearly mark verified/unverified and category (e.g. robot icon)! */}
                <Heading subtitle size={6} className="has-text-grey">
                    <SourceTag
                        navigate={props.navigate}
                        sourceName={props.source.id}
                        sourceId={props.source._id}
                        verified={false}
                    />
                    {" "} - {props.source.meta?.stats?.totalEvents||0} past events
                </Heading>
                <p className="has-text-grey-light is-size-6">
                    {props.source.description}
                </p>
                <br />
                {/* <SnitchBar
                    saveAction={() => alert("save")}
                    reportAction={() => alert("report")}
                    ignoreAction={() => alert("ignore")}
                /> */}
                <br />
                {/* TODO: widget + map "filter" for live events? */}
                <div className="box has-background-black has-text-white">
                    Past event history
                    <br />
                    <small className="has-text-grey">
                        Here are some of the 420 total events that have been
                        reported by this source.
                    </small>
                    <br />
                    <br />
                    <table
                        className="table is-fullwidth is-hoverable"
                        style={{ fontSize: "0.8em" }}
                    >
                        <tbody>
                            <tr>
                                <td>Sep 16th</td>
                                <td>
                                    Book Club: "This is not a drill"
                                    <br />
                                    <small className="has-text-grey">
                                        <i className="fa-solid fa-map-marker-alt has-text-warning"></i>
                                        &nbsp;&nbsp; SE1 9DT London
                                    </small>
                                </td>
                            </tr>
                            <tr>
                                <td>Sep 9th</td>
                                <td>
                                    Art Exhibition: "The Art of the Future"
                                    <br />
                                    <small className="has-text-grey">
                                        <i className="fa-solid fa-map-marker-alt has-text-warning"></i>
                                        &nbsp;&nbsp; SE1 9DT London
                                    </small>
                                </td>
                            </tr>
                            <tr>
                                <td>Sep 6th</td>
                                <td>
                                    Role of the Arts in the Climate Crisis
                                    <br />
                                    <small className="has-text-grey">
                                        <i className="fa-solid fa-map-marker-alt has-text-warning"></i>
                                        &nbsp;&nbsp; WC2N 5DN London
                                    </small>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br />
                
                
                Card
                <SourceBox
                        navigate={props.navigate}
                        key={props.source.id}
                        source={props.source}
                        shownSources={[]}
                        setShownSources={() => {}}
                        subscribeAction={() => {}}
                    />

                <br /><br />
                Status

                <table
                        className="table is-fullwidth is-hoverable"
                        style={{ fontSize: "0.8em" }}
                    >
                        <tbody>
                            <tr>
                                <td>
                                    Author
                                </td>
                                <td>
                                    hidden
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Last update
                                </td>
                                <td>
                                    <span className="has-text-success-dark">{moment(props.source.meta?.updated).fromNow()}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Created
                                </td>
                                <td>
                                    <span>{moment(props.source.meta?.created).fromNow()}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Spam Takedowns
                                </td>
                                <td>
                                    <span className="has-text-succsess-dark">0</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Source file
                                </td>
                                <td>
                                    <code>source/script.py</code>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Last update log
                                </td>
                                <td>
                                    <a href={props.source.meta?.lastRunLog} target="_blank" rel="noreferrer">GitHub pipeline, #12345667</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                <br /><br />
            </div>
        </div>
    ): (
        <div
            className="side-entry has-text-light"
            style={{
                display: props.show ? "flex" : "none",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            Boo!
        </div>
    )
};
export default SourceView;
