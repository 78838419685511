/*
 * Source Box
 */

import React, { useState } from "react";
import { Button, Columns, Heading } from "react-bulma-components";

import "../../index.css";
import SourceTag from "./SourceTag";
import moment from "moment";

const SourceBox = (props: {
    navigate: any;
    source: any;
    shownSources: any[];
    setShownSources: any;
    subscribeAction?: any;
    deleteAction?: any;
    manageAction?: any;
    shareAction?: any;
}) => {
    const [expanded, setExpanded] = useState(false);
    // TODO: remove columns.column spam
    return (
        <div
            className="my-5"
            onClick={() => {
                if (props.shownSources.includes(props.source.id)) {
                    props.setShownSources(
                        props.shownSources.filter((s) => s !== props.source.id)
                    );
                    setExpanded(false);
                } else {
                    props.setShownSources([
                        ...props.shownSources,
                        props.source.id,
                    ]);
                    setExpanded(true);
                }
            }}
        >
            <div
                className={
                    "box has-text-left " +
                    (props.shownSources.includes(props.source.id)
                        ? "has-background-grey-dark"
                        : "has-background-grey-darker")
                }
            >
                <Columns>
                    <Columns.Column size={6}>
                        <Heading
                            className="title is-6 has-text-warning-light text-ellipsis"
                            title={props.source.name}
                        >
                            {props.source.name}
                        </Heading>
                        <Heading className="subtitle is-7 has-text-grey text-ellipsis">
                            <SourceTag
                                navigate={props.navigate}
                                sourceName={props.source.id}
                                sourceId={props.source.id}
                                verified={false}
                            />
                            &nbsp;&nbsp;
                            <i
                                className={
                                    props.source.type === "private" ? (
                                        "fa-ghost fa has-text-grey-light"
                                    ) : (
                                        "fa-check-circle fa" +
                                            (props.shownSources.includes(
                                                props.source.id
                                            )
                                                ? "s"
                                                : "r") +
                                            " has-text-" +
                                            (props.shownSources.includes(
                                                props.source.id
                                            )
                                                ? "success"
                                                : "grey-light")
                                    )
                                }
                            ></i>
                            &nbsp;&nbsp;
                            <i className="fas fa-lock-open has-text-grey-light"></i>
                            <br />
                            {props.source.meta?.stats?.totalEvents||0} past events
                        </Heading>
                    </Columns.Column>
                    <Columns.Column
                        size={6}
                        style={{
                            backgroundImage:
                                "url(https://picsum.photos/250/300?random=" +
                                props.source.id +
                                ")",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            minHeight: "5rem",
                            padding: "0",
                            position: "relative",
                        }}
                    >
                        <div className="has-text-right" style={{
                            display: "flex",
                            visibility: (expanded ? "visible" : "hidden"),
                            opacity: (expanded ? "1" : "0"),

                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            width: "100%",
                            padding: "0.5rem",
                            position: "absolute",
                            top: "0",
                            left: "0",
                            bottom: "0",
                            right: "0",
                            backdropFilter: "blur(5px)",
                            transition: "all 0.5s ease",
                            backgroundColor: "rgba(0,0,0,0.5)",
                            gap: "0.5rem",
                            }}>

                            {props.subscribeAction && (
                                <Button className="is-pulled-right is-small is-rounded is-success is-outlined">
                                    <i className="fas fa-plus"></i>&nbsp;follow
                                </Button>
                            )}
                            {props.shareAction && (
                                <Button className="is-pulled-right is-small is-rounded is-info is-outlined" title="share link" onClick={props.shareAction}>
                                    <i className="fas fa-share-alt"></i>
                                </Button>
                            )}
                            {props.manageAction && (
                                <Button className="is-pulled-right is-small is-rounded is-warning is-outlined" title="manage" onClick={props.manageAction}>
                                    <i className="fas fa-cog"></i>
                                </Button>
                            )}
                            {props.deleteAction && (
                                <Button className="is-pulled-right is-small is-rounded is-danger is-outlined" title="delete" onClick={props.deleteAction}>
                                    <i className="fas fa-trash"></i>
                                </Button>
                            )}
                            
                        </div>
                    </Columns.Column>

                    {expanded && (
                        <Columns.Column
                            size={12}
                            className="has-text-grey-light pt-3"
                        >
                            <br />
                            <p style={{ fontFamily: "Abel" }}>
                                {props.source.description}
                            </p>
                            <br />
                            <Columns className="has-text-white">
                                <Columns.Column size={6}>
                                    <small className="text-ellipsis">
                                        <i className="fas fa-map-marker-alt"></i>
                                        &nbsp;&nbsp;{props.source.city}, {props.source.country}
                                    </small>
                                    <br />

                                    <small className="text-ellipsis">
                                        <i className="fas fa-link"></i>
                                        &nbsp;&nbsp;{props.source.urls?.home}
                                    </small>
                                </Columns.Column>
                                <Columns.Column size={6}>
                                    <small className="text-ellipsis">
                                        <i className="fas fa-code"></i>
                                        &nbsp;&nbsp;{props.source.urls?.repo}
                                        <br />
                                        <i className="fas fa-clock"></i>
                                        &nbsp;&nbsp;updated { moment(props.source.lastUpdated).fromNow() }
                                    </small>
                                </Columns.Column>
                            </Columns>

                        </Columns.Column>
                    )}
                </Columns>
            </div>
        </div>
    );
};

export default SourceBox;
