/*
 * Our simple logo
 */

import React from "react";
import { useMainContext } from "../../context/MainContext";

const Logo = (props: any) => {
    const { loading } = useMainContext();
    return (
        <b
            className={
                "has-text-warning is-size-3 logo " +
                (loading ? "moving-gradient text-gradient" : "")
            }
        >
            orda
        </b>
    );
};
export default Logo;
