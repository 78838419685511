/* generic about box */
import React from "react";
import { Button, Columns, Heading } from "react-bulma-components";
import northernLights from "../../assets/vector-beautiful-northern-lights.jpg";
import Logo from "../widgets/Logo";
import { useTranslation } from "react-i18next";
import SidebarNavigator from "../widgets/SidebarNavigator";

const AboutView = (props: any) => {
    const { t, i18n } = useTranslation();
    return (
        <div
            className="side-endtry has-text-light"
            style={{
                display: props.show ? "block" : "none",
                //backgroundImage: `url(${northernLights})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                marginTop: "-2vh",
                backgroundColor: "#00000062", // blend with background header
            }}
        >
            <img
                src={northernLights}
                alt={t("northern-lights")}
                className="image"
                style={{ 
                    width: "100%", 
                    marginBottom: "2rem",
                }}
            />
            <div className="side-entry">
                <SidebarNavigator
                    close={() => {
                        props.navigate("home");
                        props.setHighlightedEvent(undefined);
                    }}
                />
                <Heading className="title is-size-4 has-text-light">
                    <Logo /> &mdash; <span>{t("the-lively-map")}</span>
                </Heading>
                <p>
                    {t("a-humble-real-time-view-of-whats-going-on-around-you")}
                </p>
                <br />
                <br />
                <br />
                <Columns>
                    <Columns.Column style={{ textAlign: "center" }}>
                        <i
                            className="fas fa-compass fa-2x rotate has-text-warning"
                            style={{ marginBottom: "1rem" }}
                        ></i>
                        <Heading className="title is-size-4 has-text-light">
                            {t("go-explore-get")}{" "}
                            <b className="has-text-warning text-success">{t("absorbed")}</b>.
                        </Heading>
                    </Columns.Column>
                </Columns>
                <br />
                <br />
                {t(
                    "traditional-maps-are-a-static-image-of-the-space-around-you-orda-starts-from-the-principle-that-time-is-just-as-important-things-dont-just"
                )}{" "}
                <i>{t("exist")}</i>
                {t(
                    "they-happen-around-us-all-the-time-this-map-aims-to-help-you-know-your-surroundings-to-their-full-potential-and-never-miss-out-on-what-is-going-on-around-you-it-is-based-on-the"
                )}{" "}
                <a
                    href="https://openstreetmap.org"
                    target="_blank"
                    rel="noreferrer"
                    className="has-text-warning"
                >
                    OpenStreetMap
                </a>{" "}
                {t("project")}.
                <br />
                <br />
                <p className="has-text-right has-text-warning">
                    {t("a-nice-antidote-to-the-metaverse")}
                    <br />
                    <i>{t("a-friend-2023")}</i>
                </p>
                <br />
                <br />
                <Heading className="title is-size-4 has-text-light">
                    {t("where-events-spawn")}
                </Heading>
                <p>
                    {t(
                        "there-are-thousands-of-sources-for-local-events-websites-leaflets-stickers-on-traffic-lights-online-calendar-files-you-name-it-we-believe-all-these-sources-released-to-the-public-could-be-good-fits-for-this-place-but-we-cant-do-it-alone"
                    )}
                    <br />
                    <br />
                    {t(
                        "this-map-aggregates-events-from-different-sources-sources-can-be-created-in-unlimited-number-a-bit-like-channels-in-chat-apps-and-are-normally-fed-by"
                    )}{" "}
                    <u>crawlers</u>
                    {t(
                        "small-scripts-that-crawl-external-sources-and-publish-events-via-our-rest-api"
                    )}
                    <br />
                    <br />
                    {t(
                        "a-source-does-not-necessarily-have-to-be-linked-to-a-spawner-in-fact-most-events-are-normally-gathered-manually-and-in-offline-form-right-now-we-only-maintain-a-rest-api-for-publishing-events-to-a-source-but-an-interface-for-manually-adding-events-to-custom-sources-might-be-added-in-the-future"
                    )}{" "}
                    <br />
                    <br />
                    <br />
                    <br />
                    <Heading className="title is-size-4 has-text-light">
                        {t("developers-welcome")}
                    </Heading>
                    {t(
                        "using-a-rest-api-you-can-add-your-own-source-and-start-contributing-to-the-map-this-is-often-as-easy-as-building-a-simple-web-scraper-or-a-telegram-bot-or-a-mix-of-both"
                    )}
                    <br />
                    <br />
                </p>
                <div style={{ textAlign: "center" }}>
                    <Button
                        backgroundColor="dark"
                        color="primary"
                        size="small"
                        onClick={() => window.open("/developer", "_blank")}
                    >
                        {t("go-to-developer-portal")}
                    </Button>
                </div>
                <br />
                <br />
                <p>
                    {t(
                        "sources-need-not-necessarily-be-mapped-to-a-specific-provider-but-can-be-whatever-you-like-them-to-your-source-can-aggregate-events-from-multiple-sources-or-make-an-interface-to-manually-add-those-via-e-g-a-telegram-bot-or-source-them-through-your-platform-all-as-long-as-you-link-back-to-the-official-event-source-that-is"
                    )}

                    <br />
                    <br />
                    {t(
                        "developing-a-new-spawner-crawling-e-g-a-website-or-some-calendar-ics-file-usually-takes-minutes-and-we-provide-a-tiny-python-library-with-examples-on-how-to-make-quick-and-dirty-crawlers"
                    )}
                </p>
                <br />
                <br />
                <div className="is-pulled-right">
                    <Button
                        color="primary"
                        size="small"
                        backgroundColor="dark"
                        onClick={() => window.open("/developer", "_blank")}
                    >
                        {t("and-nbsp-learn-how")}
                    </Button>
                </div>
                <br />
                <br />
                <Heading className="title is-size-4 has-text-light">
                    {t("privacy-first")}
                </Heading>
                <p>
                    {t(
                        "ubiquitous-data-mining-is-disturbing-to-say-the-least-privacy-is-not-a-privilege-but-a"
                    )}{" "}
                    <a
                        href="https://www.un.org/en/universal-declaration-human-rights/"
                        className="has-text-warning"
                        target="_blank"
                        rel="noreferrer"
                    >
                        {t("fundamental-human-right")}
                    </a>
                    {t(
                        "for-this-reason-orda-is-a-no-compromise-privacy-oriented-project-we-do-not-sell-your-data-to-anyone"
                    )}
                    <br />
                    <br />
                    {t(
                        "also-we-plan-to-offer-free-end-to-end-encryption-support-for-sources-as-a-high-priority-improvement-to-this-app-so-that-you-own-the-keys-and-can-decide-who-to-share-them-with"
                    )}{" "}
                    {t(
                        "we-do-however-log-your-ip-address-and-all-data-e-g-phone-numbers-you-actively-enter-on-orda-for-security-purposes-this-is-kept-privately-inside-this-platform-only-in-order-to-prevent-abuse-more-on-this-in-our"
                    )}{" "}
                    <a
                        href="/impressum"
                        className="has-text-warning"
                        target="_blank"
                        rel="noreferrer"
                    >
                        {t("terms-of-service")}
                    </a>
                    ).
                    <br />
                    <br />
                    {/* We hope, at some point, to become profitable e.g. through
                    premium or enterprise features, or ticketing commissions.
                    But that's a problem for the future. For now, we
                    just want an app that is respectful of its users' rights.
                    <br />
                    <br /> */}
                </p>
                <br />
                <br />
                <Heading className="title is-size-4 has-text-light">
                    {t("whos-behind-this")}
                </Heading>
                <p>
                    {t("orda-is-a-side-project-by-and-nbsp")}
                    &nbsp;
                    <a
                        href="https://nokun.eu"
                        className="has-text-warning"
                        target="_blank"
                        rel="noreferrer"
                    >
                        nokun
                    </a>
                    {t(
                        "a-student-at-the-technical-university-of-munich-tum-it-started-off-as-a-fun-concept-and-design-project-in-my-free-time-but-as-it-is-growing-larger-i-would-be-happy-to-have-someone-to-and-nbsp"
                    )}{" "}
                    <a href="mailto:r@nokun.eu" className="has-text-warning">
                        {t("join-me")}
                    </a>{" "}
                    {t("on-it")}
                </p>
                <br />
                <br />
                <br />
                <Heading className="title is-size-4 has-text-grey">
                    Legal stuff
                </Heading>
                <p className="has-text-grey">
                    {t("all-rights-and-trademarks-belong-to-their-respective-owners-which-should-be-kind-of-obvious-orda-is-not-affiliated-with-any-of-the-platforms-it-aggregates-and-does-not-endorse-any-of-their-content-also-we-do-not-accept-any-liability-for-the-content-of-the-events-displayed-on-the-map-or-misuse-to-it")}
                    <br />
                    <br />
                    {t("in-general-we-do-not-accept-any-liability-for-anything-really-orda-may-make-your-cat-fat-the-earth-collapse-or-give-spoilers-to-your-favorite-series-you-have-been-warned-go-sue-someone-else")}
                </p>
            </div>
            <br />
            <br />
        </div>
    );
};
export default AboutView;
