/* todo: make this a modal */
import React from "react";
import { Button, Heading } from "react-bulma-components";
import LocalStorage from "../../utils/LocalStorage";

const UnlockView = (props: any) => {
    let [unlocked, setUnlocked] = React.useState(false);
    const saveKey = (id: string, key: string) => {
        LocalStorage.addKey(id, key);
        props.setSidebar("wallet");
    };

    // Display a large 4-digit prompt for the code and a large top hat icon on top
    return (
        <div
            className="side-entry"
            style={{ display: props.show ? "block" : "none" }}
        >
            <br />
            <br />
            {!unlocked ? (
                <div className="box has-background-light">
                    &nbsp;&nbsp;
                    <i
                        className="fas fa-2x fa-hat-wizard has-text-succaess"
                        style={{ marginBottom: "1rem", padding: "1rem" }}
                    ></i>
                    This link has been encrypted by its author.
                    <br />
                    <br />
                    <small className="has-text-grey">
                        Links are encrypted using a short code, which is not
                        stored on the server. Some links may be <i>personal</i>,
                        meaning that they can only be decrypted once before
                        being destroyed.
                        <br />
                        <br />
                        The sender will be notified when you unlock the link.
                    </small>
                    {/* <br />
                    <span className="has-text-light">
                        Decrypt the vault
                    </span> */}
                    <div className="field">
                        <div className="control">
                            <input
                                className="input is-large"
                                type="password"
                                placeholder="code"
                                style={{
                                    textAlign: "center",
                                    fontSize: "",
                                    letterSpacing: "1rem",
                                }}
                            />
                        </div>
                    </div>
                    <button
                        className="button is-large is-fullwidth is-roundsed is-success"
                        onClick={() => setUnlocked(true)}
                    >
                        🪄️ unlock 🪄️
                    </button>
                    <br />
                    <br />
                    <small className="has-text-grey">
                        By unlocking this link, you agree to our{" "}
                        <a href="/impressum" target="_blank" rel="noreferrer">
                            terms and conditions
                        </a>
                        .
                    </small>
                </div>
            ) : (
                <div>
                    <Heading size={4} className="has-text-warning">
                        Decrypted successfully 🪄️
                    </Heading>
                    <br />
                    <div className="box has-background-black has-text-white">
                        Extinction Rebellion Berlin
                        <br />
                        <small className="has-text-grey">
                            @xrberlin - Official source for XR Berlin events
                        </small>
                        <br />
                        <br />
                        <table
                            className="table is-fullwidth is-hoverable"
                            style={{ fontSize: "0.8em" }}
                        >
                            <tbody>
                                <tr>
                                    <td>Sep 6th</td>
                                    <td>
                                        Demonstration at Brandenburger Tor
                                        <br />
                                        <small className="has-text-grey">
                                            <i className="fa-solid fa-map-marker-alt has-text-warning"></i>
                                            &nbsp;&nbsp; Brandenburger Tor,
                                            10117 Berlin
                                        </small>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Sep 9th</td>
                                    <td>
                                        XR-Café Berlin: Aktionsplanung
                                        <br />
                                        <small className="has-text-grey">
                                            <i className="fa-solid fa-map-marker-alt has-text-warning"></i>
                                            &nbsp;&nbsp; B-Lage, Mareschstr. 1,
                                            12055 Berlin
                                        </small>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Sep 16th</td>
                                    <td>
                                        Book Club: "This is not a drill"
                                        <br />
                                        <small className="has-text-grey">
                                            <i className="fa-solid fa-map-marker-alt has-text-warning"></i>
                                            &nbsp;&nbsp; B-Lage, Mareschstr. 1,
                                            12055 Berlin
                                        </small>
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        colSpan={2}
                                        className="has-text-grey has-text-centered"
                                    >
                                        ( . . . )
                                    </td>{" "}
                                </tr>
                            </tbody>
                        </table>
                        <div className="has-text-right">
                            <Button
                                color="success"
                                rounded
                                outlined
                                size="small"
                                onClick={() => saveKey("xrberlin", "1234")}
                            >
                                save
                            </Button>
                            &nbsp;&nbsp;
                            <Button
                                color="info"
                                rounded
                                outlined
                                size="small"
                                onClick={() => props.setSidebar("wallet")}
                            >
                                don't save
                            </Button>
                            &nbsp;&nbsp;
                            <Button
                                color="danger"
                                rounded
                                outlined
                                size="small"
                                onClick={() => props.setSidebar("wallet")}
                            >
                                report
                            </Button>
                        </div>
                    </div>
                    <br />
                    <p></p>
                </div>
            )}
        </div>
    );
};
export default UnlockView;
