/* home, cal alerts */
import React from "react";
import { Heading } from "react-bulma-components";

const VerifyView = (props: any) => {
    let [verified, setVerified] = React.useState(false);
    return (
        <div
            className="side-entry has-text-light"
            style={{ display: props.show ? "block" : "none" }}
        >
            <div className="is-pulled-right">
                <span
                    className="tag is-rounded is-medium has-background-primary has-text-dark"
                    onClick={() => {
                        setVerified(true);
                        props.centerMapAroundNewEvent();
                    }}
                    style={{ display: verified ? "none" : "block" }}
                >
                    <b>publish</b>
                </span>
                <a
                    className="is-small has-text-light"
                    onClick={() => props.setSidebarShown(false)}
                    style={{ paddingLeft: "0.5em" }}
                >
                    <i className="fas fa-times"></i>
                </a>
            </div>
            <div style={{ paddingBottom: "5em" }}> </div>
            <div style={{ display: verified ? "none" : "block" }}>
                <Heading className="title is-4 has-text-light">
                    Gathering the mobs
                </Heading>
                <p className="subtitle is-6 has-text-light">
                    To make sure you're real, put the code you received by SMS
                </p>

                <input
                    type="tel"
                    className="modest-input title is-2 has-text-light"
                    placeholder="four digits, one try"
                    style={{
                        width: "100%",
                        textAlign: "center",
                        paddingTop: "1em",
                    }}
                />
                <br />
                <br />
                <small>
                    By verifying this event, you agree to our{" "}
                    <a className="has-text-warning">terms of service</a> and to
                    its immediate, irreversible publication. There is no way
                    back.
                </small>
            </div>
            <div style={{ display: verified ? "block" : "none" }}>
                <Heading className="title is-4 has-text-light">Boom.</Heading>
                <p className="subtitle is-6 has-text-light">
                    Your event has been published. Have fun
                </p>
                <div className="box has-background-black has-text-white">
                    <i className="fa-solid fa-check has-text-success"></i>
                    &nbsp;&nbsp; Event published
                    <br />
                    <br />
                    <div className="is-pulled-right">
                        <a
                            className="is-small has-text-light"
                            style={{ paddingLeft: "0.5em" }}
                            href="https://orda.zone/event"
                            target="_blank"
                        >
                            <i
                                className="fas fa-link has-text-warning"
                                style={{ fontSize: "1.5em" }}
                            ></i>
                        </a>
                        <br />
                    </div>
                    <Heading className="title is-5 has-text-light">
                        Gandalf's Summit
                    </Heading>
                    <p className="subtitle is-6 has-text-light">
                        Today, <b>18:00</b> - Wed, <b>21:00</b>
                        <br />
                        <i>Viktualienmarkt 18B</i>
                        <br />
                        <a className="has-text-warning">
                            orda.zone/e/3VTC0D3
                        </a>
                    </p>
                </div>
                <p>
                    To modify your event or delete it, visit at this address:{" "}
                    <br />
                    <a
                        className="has-text-warning"
                        href="https://orda.zone/edit/EVTCODESECRET12456"
                        target="_blank"
                    >
                        <i className="fa-solid fa-lock has-text-warning"></i>
                        &nbsp;&nbsp; orda.zone/edit/EVTCODESECRET12456
                    </a>
                    <br />
                    This is a secret link. Copy it in a safe place, and don't
                    share it with anyone.
                </p>
            </div>
        </div>
    );
};
export default VerifyView;
