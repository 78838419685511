import { useState } from "react";
import { Button } from "react-bulma-components";
import LocalStorage from "../../utils/LocalStorage";

const InitialSetupModal = (props: { navigate: any, setAuth?: any }) => {
    const verifyToken = () => {
        if (inviteToken === 'kitten') {
            return true;
        }
        setPNope(true);
    }

    const [ iEmail, setIEmail ] = useState('')
    const [ iUsername, setIUsername ] = useState('')
    const [ iPassword, setIPassword ] = useState('')

    const [ hasInstalledPWA, setHasInstalledPWA ] = useState(false);
    window.addEventListener('appinstalled', () => {
        setHasInstalledPWA(true);
    });
      

    const [ pNope, setPNope ] = useState(false);

    const [ page, setPage ] = useState(0);
    const [ inviteToken, setInviteToken ] = useState('');
    return (
        <div className="modal is-active" id="initialSetupModal" style={{zIndex: 999999999}}>
            <div className="modal-background has-background-black-bis"></div>
            <div className="modal-content">
                <div className="box has-text-light has-background-black-bis pt-6 pb-6 " style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <div style={{display: page === 0 ? "block" : "none", fontFamily: "Abel"}}>
                        <h1 className="title" style={{color: "white"}}>Howdy, stranger.</h1>
                        <p>
                            This is a beta version of Orda. 
                        </p>
                        <br />
                        <input type="text" placeholder="Invite token" className="modest-input title is-4 has-text-light has-background-black has-text-centered
                        " onChange={(e) => setInviteToken(e.target.value)} />
                        {pNope && <p className="has-text-danger">nope</p>}
                        <br /><br />

                        <button 
                            className="button is-success is-rounded is-outlined is-pulled-right"
                            onClick={() => verifyToken() && setPage(1) }
                        >
                            Let me in 🎩
                        </button>
                    </div>
                    <div style={{display: page === 1 ? "block" : "none", fontFamily: "Abel"}}>
                        <h1 className="title" style={{color: "white"}}>Alright.</h1>
                        Your account will be finalized when you confirm your email, but before, here are some things you need to know.
                        <br /><br />
                        <ul style={{textAlign: "left", listStyleType: "bullet",  paddingLeft: "2rem"}}>
                            <li>orda.zone is a private beta. It is currently invite-based only.</li>
                            <li>It is maintained by <a href="//nokun.eu" target="_blank">this guy</a> alone at the moment, so please be patient with all the bugs</li>
                            <li>Contribute back when possible. orda.zone is small, costly to run, and does not bring much value in read-only mode yet. As a first member, we'd like if you could:</li>
                            <ul style={{textAlign: "left", listStyleType: "bullet",  paddingLeft: "2rem"}}>
                                <li>Random joe? Add your source, start publishing events around you.</li>
                                <li>Developer? Publish your own **spawners** ("channels"), it takes minutes if you know Python, and could be even more useful to you and others than adding by hand</li>
                                <li>report bugs</li>
                                <ul>
                                    <li>Jira/gitlab/... BUT please be patient, it will take months, if not years, to sort the largest ones out</li>
                                </ul>
                            </ul>
                            <li>do NOT trust anything, move money, or expect events to even exist.</li>
                            <li>Always check the official websites and schedules. It's possible that a "spawner" is outdated and does not consider e.g. events being moved and</li>
                            <ul>
                                <li>we made this easier, all events must be linked to their orig website</li>
                                <li>...but make sure the orig website is legit, don't pay money etc! event is just as trusted as its source. source verification(tm) is sth we'll implement in the future through dns and legit checks,.</li>
                            </ul>
                            <li>do NOT abuse.</li>
                            <li>help moderate what you see. snitch on the bad stuff.</li>
                        </ul>
                        <br /><br />
                        <Button
                            className="is-success is-rounded is-outlined is-pulled-right"
                            onClick={() => setPage(2)}
                        >
                            Alright, I'm in
                        </Button>
                    </div>
                    <div style={{display: page === 2 ? "block" : "none", fontFamily: "Abel"}}>

                        
                        You have been referred by <span className="has-text-warning">@admin</span>. 
                        Make sure to thank them later.

                        <br /><br />
                        
                        Enter your email to continue. No spam, no ads, no bullshit - we promise.

                        <br /><br />
                        <div className="field has-addons" style={{display: "flex", justifyContent: "center", fontSize: "1.3rem", flexFlow: "wrap"}}>

                            <input className="modest-input has-text-light m-3" type="email" placeholder="[email]" value={iEmail} onChange={(e) => setIEmail(e.target.value)} />
                            
                            <input className="modest-input has-text-light m-3" type="text" placeholder="@[desired username]" value={iUsername} onChange={(e) => setIUsername(e.target.value)} />
                        </div>

                        <br /><br />

                        <Button
                            className="is-success is-rounded is-outlined is-pulled-right"
                            onClick={() => setPage(3)}
                        >
                            I agree to the&nbsp;
                            <a href="/terms" target="_blank" rel="noreferrer"> Terms of Service</a>
                        </Button>
                    </div>
                    <div style={{display: page === 3 ? "block" : "none", fontFamily: "Abel"}}>
                        <h1 className="title" style={{color: "white"}}>Next steps?</h1>
                        <ul style={{textAlign: "left"}}>
                            <li>confirm email</li>
                            <li>add sources from list (otherwise boring lmao)</li>
                            <li>start interacting</li>
                        </ul>
                        <br /><br />
                        <Button
                            className="is-success is-rounded is-outlined is-pulled-right"
                            onClick={() => setPage(4)}
                        >
                            Got it
                        </Button>
                    </div>
                    <div style={{display: page === 4 ? "block" : "none", fontFamily: "Abel"}}>
                        <h1 className="title" style={{color: "white"}}>Add Orda to the home screen</h1>
                        Orda is a web app. It works much better when added to the home screen. <br />

                        it works offline, it's faster, and it's easier to access. we also don't have to pay google or apple a dime to be in the app store. <br />                        
                        
                        <br /><br />

                        On Android:
                        <ul className="has-text-light ml-4" style={{ listStyleType: "circle" }}>
                            <li>tap the three dots ( <i className="fas fa-ellipsis-v"></i> ) in the top right corner</li>
                            <li>tap "add to home screen" (usually below "translate" and above "desktop site")</li>
                        </ul>
                        On iOS:
                        <ul className="has-text-light ml-4" style={{ listStyleType: "circle" }}>
                            <li>tap the share button</li>
                            <li>tap "add to home screen"</li>
                        </ul>
                        On desktop:
                        <ul className="has-text-light ml-4" style={{ listStyleType: "circle" }}>
                            <li>if you use chrome, you can install it by clicking the three dots, then "save and share", then "install page as app"</li>
                            <li>if you use firefox, you can install it by clicking the three dots, then "install site as app"</li>

                        </ul>

                        <br /><br />

                        It will make the experience much better. <br />

                        {hasInstalledPWA ? <p className="has-text-success">You've successfully installed the app. Thanks a bunch!!</p> : <p className="has-text-danger">You haven't installed the app yet. This is optional but recommended</p>}

                        <Button
                            className="is-success is-rounded is-outlined is-pulled-right"
                            onClick={() => setPage(5)}
                        >
                            Ignore and continue
                        </Button>
                    </div>
                    <div style={{display: page === 5 ? "block" : "none", fontFamily: "Abel"}}>
                        <h1 className="title" style={{color: "white"}}>How far will this get?</h1>
                        <p>
                            Depends on whether (i) you use and (ii) you like it.
                        </p>

                        <textarea cols={50} rows={20}>
                        ### [raw notes]
                        Beta ToS for Orda

                        - mild humour helps (think of duo?)
                        - suggest source or add items
                        - referral tree + contributions on leaf
                        - login mandatory
                        - internationalization
                        - self signing tickets (+ ticket proxy stub?)
                        - sticker printout + buy for support

                        (I) far from stable , heavy to scale, system may implode or delete data at any time

                        (Ii) youve been referred by x so don't do bad things or you both might get excluded

                        (Iii) give back as much as you get out. Add sources, etc. To keep able to use beta. (Or? Prioritize?) Quality is also given by quantity here 
                        </textarea>
                        <br /><br />
                        <Button
                            className="is-success is-rounded is-outlined is-pulled-right"
                            onClick={() => {
                                setPage(6);
                                LocalStorage.setPref('invite-token', inviteToken)
                                setTimeout(() => document.location.reload(), 3000);
                            }}
                        >
                            Start using Orda
                        </Button>
                    </div>
                    <div style={{display: page === 6 ? "block" : "none", fontFamily: "Abel"}} className="has-text-centered ">
                        <span className="has-text-success title is-4">All sorted. </span>
                        <br />
                        You're in. Enjoy


                        <br /><br />

                        <p className="has-text-centered">
                            <i className="fas fa-ghost fa-fade fa-3x has-text-link"></i>
                        </p>
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default InitialSetupModal;