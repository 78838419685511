/*
 * Main context: Keep global state here
 * TODO! WIP!
 */

import { createContext, useContext, useState } from "react";

const MainContext = createContext({
    loading: false,
    saveLoading: (loading: boolean) => {},
});

export const MainContextProvider = ({ children }: any) => {
    const [loading, setLoading] = useState(false);
    function saveLoading(loading: boolean) {
        alert("loading: " + loading);
        setLoading(loading);
    }

    return (
        <MainContext.Provider value={{ loading, saveLoading }}>
            {children}
        </MainContext.Provider>
    );
};

export const MainContextConsumer = MainContext.Consumer;
export const useMainContext = () => {
    const context = useContext(MainContext);
    return context;
};
export default MainContext;
