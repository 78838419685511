/*
 * Boring entry point
 */
import React from "react";
import ReactDOM from "react-dom/client";
import axios from "axios";
import { API_URL } from "./config/config";
import App from "./App";
import "./index.css";
import './i18n'

axios.defaults.baseURL = API_URL;

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(<App />);
