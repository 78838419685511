import React from "react";
import "../../index.css";
import DevNavbar from "../bars/DevNavbar";
import { Heading } from "react-bulma-components";
import { useTranslation } from "react-i18next";
import InitialSetupModal from "../views/InitialSetupModal";

const WelcomeShell = (props: { auth: boolean, setAuth: any }) => {
    return (
        <div className="has-navbar-fixed-top">
            <div
                className="container is-fluid has-background-black"
            >
                <InitialSetupModal navigate={() => {}} setAuth={props.setAuth} />
            </div>
        </div>
    );
};

export default WelcomeShell;
