/*
 * App entry point (mostly routing and global state logic)
 */
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "bulma/bulma.sass";
//import '@creativebulma/bulma-tooltip/src/sass/index.sass';

// We import all the components we need in our app
import MainShell from "./components/shells/MainShell";
import DevShell from "./components/shells/DevShell";

const App = () => {
    return (
        <div
            style={{
                fontSize: "1em",
                color: "#333",
            }}
        >
            <BrowserRouter>
                <Routes>
                    {/* Main */}
                    <Route path="/" element={<MainShell />} />
                    <Route path="/add" element={<MainShell />} />
                    <Route path="/about" element={<MainShell isAbout />} />
                    <Route path="/find" element={<MainShell isFind />} />
                    <Route path="/prefs" element={<MainShell />} />
                    <Route path="/wallet" element={<MainShell isWallet />} />

                    {/* Filters */}

                    <Route path="/e/:eventId" element={<MainShell />} />
                    <Route path="/t/:tagId" element={<MainShell />} />
                    <Route path="/c/:cityId" element={<MainShell />} />
                    <Route path="/s/:sourceId" element={<MainShell />} />
                    <Route path="/u/:objectKey" element={<MainShell />} />
                    {/* 
                    <Route path="/user" element={<MainShell />} />
                    <Route path="/user/in" element={<MainShell />} />
                    <Route path="/user/out" element={<MainShell />} /> */}

                    {/* Static routes (avoid JS and use different subdomain??) */}
                    <Route path="/developer" element={<DevShell />} />
                    <Route
                        path="/impressum"
                        element={
                            <>
                                Impressum, legal stuff, etc
                            </>
                        }
                    />
                    <Route
                        path="/api"
                        element={<>API section. Nothing to see here</>}
                    />

                    {/* 404 */}
                    <Route
                        path="*"
                        element={
                            <div
                                className="container is-fluid has-text-centered"
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "100vh",
                                    flexDirection: "column",
                                }}
                            >
                                <h1 className="title">404</h1>
                                <p>hello darkness, my old friend</p>
                            </div>
                        }
                    />
                </Routes>
            </BrowserRouter>
        </div>
    );
};

export default App;
