/* home, cal alerts */
import React from "react";
import {
    Form,
    Heading,
    Panel,
} from "react-bulma-components";
import LocalStorage from "../../utils/LocalStorage";
import SidebarNavigator from "../widgets/SidebarNavigator";

const PrefView = (props: any) => {
    const [prefs, setPrefs] = React.useState(LocalStorage.readPrefs());

    // TODO: fix this infinite loop
    // useEffect(() => {
    //     setPrefs(LocalStorage.state.prefs);
    // }, [LocalStorage.state.prefs]);
    // useEffect(() => {
    //     LocalStorage.setPrefs(prefs);
    // }, [prefs]);
    // useEffect(() => {
    //     Object.values = Object.values || function(o:any){return Object.keys(o).map(function(k){return o[k]})};
    //     setKeyWallet(Object.values(LocalStorage.state.keyWallet));
    // }, [LocalStorage.state.keyWallet]);
    // useEffect(() => {
    //     LocalStorage.setKeyWallet(keyWallet);
    // }, [keyWallet]);
    const [activeTabPrefs, setActiveTabPrefs] = React.useState(0);


    const PrefCheckbox = (props: { pref: string }) => {
        return (
            <Form.Checkbox
                style={{ marginLeft: "auto" }}
                checked={prefs[props.pref]}
                onChange={() =>
                    setPrefs({ ...prefs, [props.pref]: !prefs[props.pref] })
                }
            />
        );
    };

    return (
        <div
            className="side-entry has-text-light has-background-grey-300"
            style={{ display: props.show ? "block" : "none" }}
        >
            <SidebarNavigator
                close={() => props.navigate("home")}
            />
            <br /><br />

            <Heading size={4} className="has-text-warning">
                Preferences
            </Heading>

            <Panel className="has-background-light" style={{ height: "calc(100vh - 15rem)" }}>
                <Panel.Tabs>
                    <Panel.Tabs.Tab
                        active={activeTabPrefs === 0}
                        onClick={() => setActiveTabPrefs(0)}
                    >
                        View
                    </Panel.Tabs.Tab>
                    <Panel.Tabs.Tab
                        active={activeTabPrefs === 1}
                        onClick={() => setActiveTabPrefs(1)}
                    >
                        User & Account
                    </Panel.Tabs.Tab>
                    <Panel.Tabs.Tab
                        active={activeTabPrefs === 2}
                        onClick={() => setActiveTabPrefs(2)}
                    >
                        Support
                    </Panel.Tabs.Tab>
                </Panel.Tabs>

                <Panel.Block
                    style={{ display: activeTabPrefs === 0 ? "flex" : "none" }}
                    className="has-background-grey-lighter has-text-centered"
                >
                    <span className="has-text-grey-ligaht">Interface</span>
                </Panel.Block>
                <Panel.Block
                    style={{ display: activeTabPrefs === 0 ? "flex" : "none" }}
                >
                    <Panel.Icon renderAs="span">
                        <i className="fa-solid fa-th"></i>
                    </Panel.Icon>
                    <span>Split Layout</span>
                    <span
                        className="has-text-grey-light"
                        style={{ marginLeft: "1rem" }}
                    >
                        is arguably cuter than the default
                    </span>
                    <PrefCheckbox pref="view.tiledMode" />
                </Panel.Block>
                <Panel.Block
                    style={{ display: activeTabPrefs === 0 ? "flex" : "none" }}
                    onClick={ props.setHome }
                >
                    <Panel.Icon renderAs="span">
                        <i className="fa-solid fa-home"></i>
                    </Panel.Icon>
                    <span>Set Home</span>
                    <span
                        className="has-text-grey-light"
                        style={{ marginLeft: "1rem" }}
                    >
                        to this position
                    </span>
                </Panel.Block>
                <Panel.Block
                    style={{ display: activeTabPrefs === 0 ? "flex" : "none" }}
                >
                    <Panel.Icon renderAs="span">
                        <i className="far fa-lightbulb"></i>
                    </Panel.Icon>
                    <span>Moth Mode</span>
                    <span
                        className="has-text-grey-light"
                        style={{ marginLeft: "1rem" }}
                    >
                        yearn för the light, bröther
                    </span>
                    <PrefCheckbox pref="mothMode" />
                </Panel.Block>
                <Panel.Block
                    style={{ display: activeTabPrefs === 0 ? "flex" : "none" }}
                >
                    <Panel.Icon renderAs="span">
                        <i className="fa-solid fa-font"></i>
                    </Panel.Icon>
                    <span>Font</span>
                    <span
                        className="has-text-grey-light"
                        style={{ marginLeft: "1rem" }}
                    >
                        whatever reads best
                    </span>
                    <Form.Select style={{ marginLeft: "auto" }} size="small">
                        <option style={{ fontFamily: "Oswald" }}>
                            default
                        </option>
                        <option style={{ fontFamily: "Dyslexie" }}>
                            readable
                        </option>
                        <option style={{ fontFamily: "Urbanist" }}>
                            cliché
                        </option>
                        <option style={{ fontFamily: "Helvetica" }}>
                            modern
                        </option>
                        <option style={{ fontFamily: "Source Serif Pro" }}>
                            classy
                        </option>
                        <option style={{ fontFamily: "Comic Sans" }}>
                            clownish
                        </option>
                        <option style={{ fontFamily: "monospace" }}>
                            nerdy
                        </option>
                        <option style={{ fontFamily: "cursive" }}>fancy</option>
                    </Form.Select>
                </Panel.Block>
                <Panel.Block
                    style={{ display: activeTabPrefs === 0 ? "flex" : "none" }}
                >
                    <Panel.Icon renderAs="span">
                        <i className="fa-solid fa-language"></i>
                    </Panel.Icon>
                    <span>Language</span>
                    <span
                        className="has-text-grey-light"
                        style={{ marginLeft: "1rem" }}
                    >
                        (beta)
                    </span>
                    <Form.Select style={{ marginLeft: "auto" }} size="small">
                        <option>🇬🇧️ English</option>
                        <option>🇩🇪️ German</option>
                        <option>🇮🇹️ Italian</option>
                        <option>🥖️ French</option>
                        <option>🇳🇱️ Dutch</option>
                        <option>🦆️ Duck</option>
                    </Form.Select>
                </Panel.Block>

                <Panel.Block
                    style={{ display: activeTabPrefs === 0 ? "flex" : "none" }}
                >
                    <Panel.Icon renderAs="span">
                        <i className="fa-solid fa-fish-fins"></i>
                    </Panel.Icon>
                    <span>Translate</span>
                    <span
                        className="has-text-grey-light"
                        style={{ marginLeft: "1rem" }}
                    >
                        content of events (beta)
                    </span>
                    <PrefCheckbox pref="translate" />
                </Panel.Block>
                {/* <Panel.Block style={{display: (activeTabPrefs === 0 ? "flex" : "none")}}>
                    <Panel.Icon renderAs="span">
                        <i className="fa-solid fa-palette"></i>
                    </Panel.Icon>
                    <span>Theme</span>
                    <span className="has-text-grey-light" style={{marginLeft: "1rem"}}>
                        coming soon (TM)
                    </span>
                </Panel.Block>
                <Panel.Block style={{display: (activeTabPrefs === 0 ? "flex" : "none")}}>
                    <Panel.Icon renderAs="span">
                        <i className="fa-solid fa-columns"></i>
                    </Panel.Icon>
                    <span>Layout</span>
                    <span className="has-text-grey-light" style={{marginLeft: "1rem"}}>
                        coming soon (TM)
                    </span>
                </Panel.Block> */}

                <Panel.Block
                    style={{ display: activeTabPrefs === 0 ? "flex" : "none" }}
                    className="has-background-grey-lighter"
                >
                    <span className="?has-text-grey-light">Accessibility</span>
                </Panel.Block>

                <Panel.Block
                    style={{ display: activeTabPrefs === 0 ? "flex" : "none" }}
                >
                    <Panel.Icon renderAs="span">
                        <i className="fa-solid fa-map-marker-alt"></i>
                    </Panel.Icon>
                    <span>Boring Markers</span>
                    <span
                        className="has-text-grey-light"
                        style={{ marginLeft: "1rem" }}
                    >
                        for readability and <i>squareophobics</i>
                    </span>
                    <PrefCheckbox pref="view.acc.boringMarkers" />
                </Panel.Block>

                <Panel.Block
                    style={{ display: activeTabPrefs === 0 ? "flex" : "none" }}
                >
                    <Panel.Icon renderAs="span">
                        <i className="fa-solid fa-font"></i>
                    </Panel.Icon>
                    <span>Obvious Buttons</span>
                    <span
                        className="has-text-grey-light"
                        style={{ marginLeft: "1rem" }}
                    >
                        replace all icons with text
                    </span>
                    <PrefCheckbox pref="view.acc.obviousButtons" />
                </Panel.Block>

                <Panel.Block
                    style={{ display: activeTabPrefs === 0 ? "flex" : "none" }}
                >
                    <Panel.Icon renderAs="span">
                        <i className="fa-solid fa-feather"></i>
                    </Panel.Icon>
                    <span>Basic Graphics</span>
                    <span
                        className="has-text-grey-light"
                        style={{ marginLeft: "1rem" }}
                    >
                        for older devices, slow networks
                    </span>
                    <PrefCheckbox pref="view.acc.basicGraphics" />
                </Panel.Block>


                <Panel.Block
                    style={{ display: activeTabPrefs === 1 ? "flex" : "none" }}
                    className="has-background-grey-lighter has-text-centered"
                >
                    <span className="has-text-grey-ligaht">Content</span>
                </Panel.Block>
                <Panel.Block
                    style={{ display: activeTabPrefs === 1 ? "flex" : "none" }}
                >
                    <Panel.Icon renderAs="span">
                        <i className="fa-solid fa-hammer"></i>
                    </Panel.Icon>
                    <span>Moderate</span>
                    <p
                        className="has-text-grey-light"
                        style={{ marginLeft: "1rem" }}
                    >
                        show <i>fresh</i> but unmoderated events <br />
                        <span className="has-text-warning-dark"></span>
                    </p>
                    <PrefCheckbox pref="me.modMode" />
                </Panel.Block>
                <Panel.Block
                    style={{
                        display: activeTabPrefs === 1 ? "flex" : "none",
                        borderTop: "none",
                    }}
                    className="has-text-warning-dark"
                >
                    <i className="fas fa-exclamation-triangle"></i> &nbsp;This
                    might show bad stuff! Report any abuse ASAP
                </Panel.Block>
                <Panel.Block
                    style={{ display: activeTabPrefs === 1 ? "flex" : "none" }}
                    className="has-background-grey-lighter has-text-centered"
                >
                    <span className="has-text-grey-ligaht">Account</span>
                </Panel.Block>
                <Panel.Block
                    style={{ display: activeTabPrefs === 1 ? "flex" : "none" }}
                >
                    <Panel.Icon renderAs="span">
                        <i className="fa-solid fa-user"></i>
                    </Panel.Icon>
                    <span>Profile &amp; Passwords</span>
                </Panel.Block>

                <Panel.Block
                    style={{ display: activeTabPrefs === 1 ? "flex" : "none" }}
                    onClick={() => props.navigate("sources")}
                >
                    <Panel.Icon renderAs="span">
                        <i className="fa-solid fa-bug"></i>
                    </Panel.Icon>
                    <span>Manage Sources</span>
                    <span
                        className="has-text-grey-light"
                        style={{ marginLeft: "1rem" }}
                    >
                        directs to Sources tab
                    </span>
                </Panel.Block>
                <Panel.Block
                    style={{ display: activeTabPrefs === 1 ? "flex" : "none" }}
                >
                    <Panel.Icon renderAs="span">
                        <i className="fa-solid fa-envelope"></i>
                    </Panel.Icon>
                    <span>Communication</span>
                    <span
                        className="has-text-grey-light"
                        style={{ marginLeft: "1rem" }}
                    >
                        email, notifications, etc.
                    </span>
                </Panel.Block>
                <Panel.Block
                    style={{ display: activeTabPrefs === 1 ? "flex" : "none" }}
                >
                    <Panel.Icon renderAs="span">
                        <i className="fa-solid fa-calendar"></i>
                    </Panel.Icon>
                    <span>History</span>
                    <span
                        className="has-text-grey-light"
                        style={{ marginLeft: "1rem" }}
                    >
                        of account activity, events, etc.
                    </span>
                </Panel.Block>

                <Panel.Block
                    style={{ display: activeTabPrefs === 1 ? "flex" : "none" }}
                    className="has-background-grey-lighter has-text-centered"
                >
                    <span className="has-text-grey-ligaht">Transparency</span>
                </Panel.Block>
                <Panel.Block
                    style={{ display: activeTabPrefs === 1 ? "flex" : "none" }}
                >
                    <Panel.Icon renderAs="span">
                        <i className="fa-solid fa-user"></i>
                    </Panel.Icon>
                    <span>Data &amp; Privacy</span>
                    <span
                        className="has-text-grey-light"
                        style={{ marginLeft: "1rem" }}
                    >
                        e.g. GDPR (spoiler: we don't spy)
                    </span>
                </Panel.Block>
                <Panel.Block
                    style={{ display: activeTabPrefs === 1 ? "flex" : "none" }}
                >
                    <Panel.Icon renderAs="span">
                        <i className="fa-solid fa-crown"></i>
                    </Panel.Icon>
                    <span>Plan</span>
                    <span
                        className="has-text-grey-light"
                        style={{ marginLeft: "1rem" }}
                    >
                        find out the perks of Zero and other plans
                    </span>
                </Panel.Block>
                <Panel.Block
                    style={{ display: activeTabPrefs === 1 ? "flex" : "none" }}
                >
                    <Panel.Icon renderAs="span">
                        <i className="fa-solid fa-file"></i>
                    </Panel.Icon>
                    <span>Terms of Service</span>
                    <span
                        className="has-text-grey-light"
                        style={{ marginLeft: "1rem" }}
                    >
                        disclaimers and legal stuff
                    </span>
                </Panel.Block>
                <Panel.Block
                    style={{ display: activeTabPrefs === 1 ? "flex" : "none" }}
                >
                    <Panel.Icon renderAs="span">
                        <i className="fas fa-fire"></i>
                    </Panel.Icon>
                    <span>Delete</span>
                    <span
                        className="has-text-grey-light"
                        style={{ marginLeft: "1rem" }}
                        onClick={() => alert("TODO!")}
                    >
                        my account and browser data, forever
                    </span>
                </Panel.Block>
            </Panel>

            {/* <p>
                Set Home <br />
                <p className="has-text-grey-light" style={{marginLeft: "1rem"}}>
                    To this position
                </p> <br />
                Sources <br />
                <p className="has-text-grey-light" style={{marginLeft: "1rem"}}>
                    Public <br />
                    Followed <br />
                </p> <br />
                View <br />
                <p className="has-text-grey-light" style={{marginLeft: "1rem"}}>
                    Moth Mode <br />
                    Font <br />
                    Theme <br />
                    Layout <br />
                </p> <br />
                Me <br />
                <p className="has-text-grey-light" style={{marginLeft: "1rem"}}>
                    Profile <br />
                    Events <br />
                    Moderation <br />
                    Spawners <br />
                </p> <br />
                Account <br />
                <p className="has-text-grey-light" style={{marginLeft: "1rem"}}>
                    Data &amp; Password <br />
                    Communications <br />
                    Delete <br />
                </p> <br />
            </p> */}
            {/* <Heading size={4} className="has-text-warning">Support</Heading>
            <p>
                orda.zone v0.1.0 (alpha). Expect some bugs &#128030; <br />

                For any questions, write us at <a href="mailto:" className="has-text-warning">contact@orda.com</a> <br />
            </p> */}
        </div>
    );
};
export default PrefView;
