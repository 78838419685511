/*
 * Widget with back & close buttons
 */

import React from "react";
import { Button } from "react-bulma-components";

type Props = {
    back?: () => void;
    reset?: () => void;
    close?: () => void;
};

const SidebarNavigator: React.FC<Props> = ({ back, reset, close }) => {
    return (
        <div className="sidebar-header">
            {back && (
                <div className="is-pulled-left">
                    <span
                        className="is-small has-text-light"
                        onClick={() => back()}
                    >
                        <i className="fas fa-arrow-left"></i>
                    </span>
                </div>
            )}
            { reset && (
                <div className="is-pulled-left">
                    <Button
                        className="is-small has-text-light is-danger is-outlined is-rounded"
                        onClick={() => window.confirm("Are you sure you want to reset this draft?") && reset()}
                    >
                        reset draft
                    </Button>
                </div>
            )}
            {close && (
                <div className="is-pulled-right">
                    <span
                        className="is-small has-text-light"
                        onClick={() => close()}
                    >
                        <i className="fas fa-times"></i>
                    </span>
                </div>
            )}
        </div>
    );
};

export default SidebarNavigator;
