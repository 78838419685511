/* todo: make this a modal */
import React from "react";
import { Button, Columns, Heading, Panel, Table } from "react-bulma-components";
import ApiBox from "../../db/ApiBox";
import LocalStorage from "../../utils/LocalStorage";
import moment from "moment";
import SidebarNavigator from "../widgets/SidebarNavigator";

const WalletView = (props: any) => {
    // TODO: change sources also in main view etc!
    const [activeTabWallet, setActiveTabWallet] = React.useState(1);
    const [ walletItems, setWalletItems ] = React.useState<any[]>([
        { id: "xrberlin", name: "XR Berlin: Demo Klima", type: "public" },
        { id: "londonarts", name: "London Arts: The Mousetrap Improv", type: "public" },
    ]);
    const [ search, setSearch ] = React.useState<string>("");
    const [ shownSources, setShownSources ] = React.useState<any[]>([]);
    const [keyWallet, setKeyWallet] = React.useState<
        { id: string; date: string; key: string; keyHash: string }[]
    >(LocalStorage.getKeysArray());

    
    return (
        <div
            className="side-entry has-text-centered"
            style={{
                display: props.show ? "block" : "none",
            }}
        >
            <SidebarNavigator
                close={() => props.navigate("home")}
            />
            <br /><br />
            <Heading className="title is-4 has-text-warning-light has-text-centered">
                Your wallet 
            </Heading>
            <div className="has-text-grey-light">
                Here are all the tickets you own, and the keys to the protected sources you have access to.
                <br />
                <br />
                <Columns className="is-mobile is-centered">
                    <Columns.Column size={12} id="search-bar">
                        <div className="field has-addons">
                            <div className="control is-expanded">
                                <input
                                    className="input has-text-white has-background-black is-rounded no-borders"
                                    type="text"
                                    autoFocus
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                            </div>
                        </div>
                    </Columns.Column>
                        
                    {walletItems.map((source: any) => (
                        <Columns.Column size={6} key={source.id} onClick={() => { 
                            if(shownSources.includes(source.id)) {
                                setShownSources(shownSources.filter((s) => s !== source.id));
                            } else {
                                setShownSources([...shownSources, source.id]);
                            }
                        }}>
                            <div className={"box has-text-left " + (shownSources.includes(source.id) ? "has-background-grey-darker" : "has-background-black")}>
                                <Heading className="title is-6 has-text-warning-light text-ellipsis" title={source.name}>
                                    <i className={"fa-check-circle fa" + (shownSources.includes(source.id) ? "s" : "r") + " has-text-" + (shownSources.includes(source.id) ? "success" : "white")}></i>
                                    &nbsp;&nbsp;
                                    {source.name}
                                </Heading>
                                <Heading className="subtitle is-7 has-text-grey text-ellipsis">
                                    @{source.id}
                                    <br />
                                    420 events
                                </Heading>
                            </div>
                        </Columns.Column>
                    ))}
                    { walletItems.length % 2 === 1 ? <Columns.Column size={6}></Columns.Column> : null}
                </Columns>
            </div>

            <br />
            <br />
            <Heading className="title is-4 has-text-warning-light has-text-centered">
                Your Timeline 
            </Heading>
            <div className="has-text-grey-light">
                Upcoming events from the sources you follow.
            </div>
            {/* TODO: ticket importer as well!! (pdfs etc)

            <Panel className="has-background-light is-fullwidth">
                <Panel.Tabs>
                    <Panel.Tabs.Tab
                        active={activeTabWallet === 0}
                        onClick={() => setActiveTabWallet(0)}
                    >
                        Passes
                    </Panel.Tabs.Tab>
                    <Panel.Tabs.Tab
                        active={activeTabWallet === 1}
                        onClick={() => setActiveTabWallet(1)}
                    >
                        Encryption Keys
                    </Panel.Tabs.Tab>
                </Panel.Tabs>

                <Panel.Block
                    style={{
                        display: activeTabWallet === 0 ? "block" : "none",
                    }}
                >
                    <p>
                        Your tickets will be stored here. <br />
                        <br />
                        But this is not yet implemented. <br />
                        <br />
                    </p>
                    {/* <TicketBox /> * /}
                </Panel.Block>

                <Panel.Block
                    style={{
                        display: activeTabWallet === 1 ? "block" : "none",
                    }}
                >
                    <p
                        style={{
                            textAlign: "center",
                            filter: "drop-shadow(0 0 0.75rem rgba(120,120,120,0.5)) brightness(0.65)",
                        }}
                        className="moving-gradient text-gradient"
                    >
                        <br />
                        <i className="fas fa-hat-wizard fa-2x"></i>&nbsp; You
                        own the keys to these sources:
                        <br />
                        <br />
                    </p>
                    <br />
                    <Table className="is-fullwidth">
                        <tbody>
                            {keyWallet.length > 0 ? (
                                keyWallet.map((key: any) => {
                                    return (
                                        <tr key={key.id} title={key.keyHash}>
                                            <td>
                                                {key.id}
                                                <br />
                                                <small className="has-text-grey">
                                                    since{" "}
                                                    {moment(key.date).format(
                                                        "MMMM Do, YYYY"
                                                    )}
                                                </small>
                                            </td>
                                            <td
                                                style={{
                                                    textAlign: "right",
                                                    verticalAlign: "middle",
                                                }}
                                            >
                                                <Button
                                                    color="danger is-rounded"
                                                    size="small"
                                                    onClick={() =>
                                                        setKeyWallet(
                                                            keyWallet.filter(
                                                                (k) =>
                                                                    k.id !==
                                                                    key.id
                                                            )
                                                        )
                                                    }
                                                >
                                                    forget
                                                </Button>
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td
                                        colSpan={2}
                                        style={{ textAlign: "center" }}
                                    >
                                        <p className="has-text-grey p-5">
                                            <i className="fa-solid fa-ghost fa-2x"></i>{" "}
                                            <br />
                                            none yet?
                                        </p>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Panel.Block>
            </Panel> */}
        </div>
    );
};
export default WalletView;
