/*
 * A bar with follow and report features
 * Should bring to the Snitch Logic(TM) of verifiable "snitched"
 * event hash but not master encryption key
 */

const SourceTag = (props: {
    navigate: any;
    sourceName: string;
    sourceId: string;
    verified: boolean;
}) => {
    return (
        <span
            onClick={() => props.navigate("source", props.sourceId)}
            className="tag is-dark"
            style={{ cursor: "pointer" }}
        >
            <i className="fas fa-satellite-dish" />
            &nbsp;&nbsp;
            <b>{props.sourceName}</b>
            {props.verified && <>
                &nbsp;&nbsp;
                <i className="fas fa-check-circle has-text-success" /> 
            </>}
        </span>
    );
};
export default SourceTag;
