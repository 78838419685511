/*
 * Container for floating source list on main map view
 */
import React from "react";

import "../../index.css";
import TopicSwitcher from "./TopicSwitcher";

const TopicSwitcherBox = (props: any) => {
    // const [timeslots, setTimeslots] = React.useState([
    //     {
    //         date: "01.06.2022",
    //         start: "18:00",
    //         end: "21:00",
    //     },
    // ]);
    return (
        <div className="is-black source-switcher box">
            <TopicSwitcher {...props} showIcon={true} showName={true} selectMode="single" />
        </div>
    );
};

export default TopicSwitcherBox;
