/*
 * Local Storage helper library
 * TODO: SAVE STATE as React state and update dynamically from there!
 * e.g. so the encapsulation to the browser storage is via the state
 */

import { LatLngTuple } from "leaflet";
import { useEffect, useState } from "react";
import TimezoneHelper from "./TimezoneHelper";

interface LocalStorageState {
    prefs?: { [key: string]: any };
    keyWallet: {
        [key: string]: {
            key: string;
            date: string;
            id: string;
            keyHash: string;
        };
    };
}

const LocalStorage = {
    state: {
        prefs: undefined,
        keyWallet: {} as { [key: string]: { key: string; date: string } },
    } as LocalStorageState,

    readPrefs() {
        try {
            const prefs = JSON.parse(localStorage.getItem("prefs")!) as {
                [key: string]: any;
            };
            if (prefs) {
                return prefs;
            } else {
                console.log("No prefs found in local storage");
            }
        } catch (e) {
            console.log("Error parsing prefs from local storage: " + e);
        }
        this.setPrefs({});
        return {};
    },

    readKeyWallet() {
        try {
            const keys = JSON.parse(localStorage.getItem("wallet.keys")!) as {
                [key: string]: { key: string; date: string };
            };
            if (keys) {
                return keys;
            }
        } catch (e) {
            console.log("Error parsing keys from local storage: " + e);
        }

        this.setKeyWallet({});
        return {};
    },

    setPrefs(prefs: any) {
        this.state.prefs = prefs;
        this.useEffect();
    },

    setKeyWallet(keyWallet: any) {
        this.state.keyWallet = keyWallet;
        this.useEffect();
    },

    getPref(key: string) {
        if (this.state.prefs === undefined)
            this.state.prefs = this.readPrefs();
        return this.state.prefs[key];
    },

    setPref(key: string, value: any) {
        console.log("Setting " + key + " changed from " + this.getPref(key) + " to " + value);
        this.setPrefs({ ...this.state.prefs, [key]: value });
    },

    getHome() {
        try {
            const home = JSON.parse(this.getPref("view.home")) as LatLngTuple;
            if (home) return home;
        } catch (e) {
            console.log("Error parsing home location from local storage: " + e);
        }
        // if no home location is set, set it to the current capital?
        this.setHome(TimezoneHelper.getLocaleCapital() as LatLngTuple);
        return TimezoneHelper.getLocaleCapital();
    },
    setHome(home: LatLngTuple) {
        this.setPref("view.home", JSON.stringify(home));
        console.log("Home location set to " + this.getPref("view.home"));
    },

    getSavedSources() {
        if (this.getPref("user.sources") === undefined) 
            return [];
        else
            return JSON.parse(this.getPref("user.sources"));
    },
    setSavedSources(sources: string[]) {
        this.setPref("user.sources", JSON.stringify(sources));
    },

    addKey(id: string, key: string) {
        this.state.keyWallet[id] = {
            id: id,
            key: key,
            keyHash: "TODO",
            date: new Date().toISOString(),
        };
        this.useEffect();
    },

    getKey(id: string) {
        return this.state.keyWallet[id];
    },

    getKeysArray() {
        let acc: { id: string; key: string }[] = [];
        const reducer = (acc: any[], key: string) => {
            acc.push({
                id: key,
                key: this.state.keyWallet[key].key,
                date: this.state.keyWallet[key].date,
            });
            return acc;
        };
        return Object.keys(this.state.keyWallet).reduce(reducer, acc);
    },

    useEffect() {
        console.log("Saving to local storage: ", this.state);
        localStorage.setItem("prefs", JSON.stringify(this.state.prefs));
        localStorage.setItem(
            "wallet.keys",
            JSON.stringify(this.state.keyWallet)
        );
    },
};

export const LocalStorageObject = {
    prefs: LocalStorage.state.prefs,
    keyWallet: LocalStorage.state.keyWallet,

    readPrefs() {
        return LocalStorage.readPrefs();
    },

    readKeyWallet() {
        return LocalStorage.readKeyWallet();
    },

    setPrefs(prefs: any) {
        LocalStorage.setPrefs(prefs);
    },

    setKeyWallet(keyWallet: any) {
        LocalStorage.setKeyWallet(keyWallet);
    },

    getPref(key: string) {
        return LocalStorage.getPref(key);
    },

    setPref(key: string, value: string) {
        LocalStorage.setPref(key, value);
    },

    getHome() {
        return LocalStorage.getHome();
    },

    setHome(home: LatLngTuple) {
        LocalStorage.setHome(home);
    },

    addKey(id: string, key: string) {
        LocalStorage.addKey(id, key);
    },

    getKey(id: string) {
        return LocalStorage.getKey(id);
    },

    getKeysArray() {
        return LocalStorage.getKeysArray();
    },
};

export const useLocalStorage = () => {
    const [prefs, setPrefs] = useState(LocalStorageObject.prefs);
    const [keyWallet, setKeyWallet] = useState(LocalStorageObject.keyWallet);

    useEffect(() => {
        const handleStorageChange = () => {
            setPrefs(LocalStorageObject.prefs);
            setKeyWallet(LocalStorageObject.keyWallet);
        };

        window.addEventListener("storage", handleStorageChange);

        return () => {
            window.removeEventListener("storage", handleStorageChange);
        };
    }, []);

    return { prefs, keyWallet };
};

export default LocalStorage;
