import { useState } from "react";
import { Button } from "react-bulma-components";

const GoToBetaModal = (props: { navigate: any, show: boolean, setShow: any }) => {
    return (
        <div className={"modal " + (props.show ? "is-active" : "")} id="betaModal" style={{zIndex: 999999999}}>
            <div className="modal-background"></div>
            <div className="modal-content">
                <div className="box has-text-light has-background-black-ter p-6" style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <i className="fas fa-dna fa-4x m-4 has-text-success moving-gradient text-gradient"></i>
                    <h1 className="title" style={{color: "white"}}>Unleash the Beta</h1>
                    <p>
                        Private sources. Global search. Timelines. Ticketing.
                    </p>
                    <br />
                    <p> 
                        orda.zone gets more fun when it's not read-only.
                    </p>
                    <p>
                        But for now, you will need an invite code for that.
                    </p>
                    <br />
                    <input type="email" className="modest-input has-text-danger is-size-5 has-background-white is-fullwidth p-3" autoFocus placeholder="email (for waitlist)" />
                    <br /><br />
                    <button 
                        className="button is-warning is-rounded is-outlined is-pulled-right"
                        onClick={() => props.setShow(false)}
                    >
                        cancel
                    </button>
                </div>
            </div>
        </div>
    );
}

export default GoToBetaModal;
